import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment'
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    padding: 12,
  },
  section: {
    flexDirection: 'column',
    flexGrow: 1,
    border: 1,
    display: 'flex',
  },
  text: {
    fontSize: 13,
    fontFamily: 'Times-Roman',
    textAlign: 'center',
    borderBottom: 1,
    width: '100%',
  },
  // invoice-title start
  headertext: {
    fontSize: 10,
    fontFamily: 'Times-Roman',
    borderBottom: 1,
    width: '100%',
    padding: 6
  },
  head: {
    fontSize: 10,
    textAlign: "center"
  },
  date: {
    fontSize: 10,
    width: "50%",
    padding: 0,
    textAlign: "right"
  },
  title: {
    fontWeight: "bolder",
    fontSize: 15,
    textAlign: "center",
    width: "100%",
    marginBottom: 9
  },
  companyaddress: {
    fontWeight: "bolder",
    fontSize: 10,
    textAlign: "center",
    width: "100%",
    marginBottom: 0
  },
  companymobile: {
    fontWeight: "bolder",
    fontSize: 10,
    textAlign: "center",
    width: "100%",
    marginBottom: 7
  },
  companygst: {
    fontWeight: "bold",
    fontSize: 11,
    textAlign: "center",
    width: "100%",
    marginBottom: 7
  },
  name: {
    fontSize: 10,
    width: "50%",
    textAlign: "left"
  },
  // invoice-title start
  // table start
  table: {
    display: 'flex',
    padding: 6,
    justifyContent: 'flex-end',

  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableCell: {
    flex: 4,
    fontSize: 9,
    fontFamily: 'Times-Roman',
  },
  // table end 
  // product start
  producthead: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    borderBottom: 1
  },
  producttitle: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    display: 'flex',
    flexDirection: 'row',
    width: "100%",
    borderBottom: 1

  },
  productbody: {
    fontSize: 8,
    fontFamily: 'Times-Roman',
    display: 'flex',
    flexDirection: 'row',
  },
  no: {
    width: "20%",
    borderRight: 1,
    padding: 5
  },
  dataDate: {
    width: "30%",
    borderRight: 1,
    padding: 5,
  },
  dataReceipt: {
    width: "35%",
    borderRight: 1,
    padding: 5,
  },
  partyname: {
    width: "90%",
    borderRight: 1,
    padding: 5,
  },
  dataSubTotal: {
    width: "40%",
    borderRight: 1,
    padding: 5,
  },
  dataTax: {
    width: "40%",
    borderRight: 1,
    padding: 5,
  },
  dataend: {
    width: "40%",
    padding: 5,
  },
  // product end

  lastpage: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 7,
    marginBottom: 20
  },
  totaledit: {
    fontSize: 14,
    fontWeight: "bolder",
    marginLeft: 260
  },
  suptotal: {
    fontSize: 9,
    fontWeight: "bolder",
    marginLeft: 77
  },
  supgst: {
    fontSize: 9,
    fontWeight: "bolder",
    marginLeft: 30
  },
  supgrandtotal: {
    fontSize: 9,
    fontWeight: "bolder",
    marginLeft: 25
  },

});
const PurchaseReport = ({ data, fromdate, todate }) => {
  const formattedFromDate = moment(fromdate).format('DD-MM-YYYY');
  const formattedtoDate = moment(todate).format('DD-MM-YYYY');

  const subTotal = data.reduce((acc, item) => acc + parseFloat(item.sum_total) || 0, 0);
  const subgrandtotal = data.reduce((acc, item) => acc + item.total, 0);
  const subgst = data.reduce((acc, item) => acc + (item.total - item.sum_total), 0)
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={styles.headertext}>
              <View style={styles.head}>
                <Text style={styles.title}>{data[0].company_details.company_name} </Text>
                <Text style={styles.companyaddress}>{data[0].company_details.address} </Text>
                <Text style={styles.companymobile}>{data[0].company_details.mobile_number} </Text>
                <Text style={styles.companygst}>GSTIN : {data[0].company_details.gst_no} </Text>
                {formattedFromDate !== "" ? (<Text style={styles.companygst}>Purchase Details ({formattedFromDate} To {formattedtoDate}) </Text>) : ""}
              </View>
            </View>
            {/* <View style={styles.headertext}>
              <View style={styles.head}>
                <Text style={styles.name}>Name: Thirumurugan</Text>
                <Text style={styles.date}> Date : 13-12-2022 - 15-01-2022</Text>
              </View>
            </View> */}
          </View>
          <View style={styles.producthead}>
            <View style={styles.producttitle}>
              <Text style={styles.no}>S No</Text>
              <Text style={styles.dataDate}>Bill Date</Text>
              <Text style={styles.dataDate}>Stock Date</Text>
              <Text style={styles.dataReceipt}>Supplier Inv.No</Text>
              <Text style={styles.partyname}>Name</Text>
              <Text style={styles.dataSubTotal}>SubTotal</Text>
              <Text style={styles.dataTax}>TaxAmount</Text>
              <Text style={styles.dataend}>GrandTotal</Text>
            </View>
            <View style={styles.bodydescription}>
              {data.map((product, index) => {
                const gst = (product.total - product.sum_total).toFixed(2); // Calculate GST
                return (
                  <View style={styles.productbody} key={index}>
                    <Text style={styles.no}>{index + 1}</Text>
                    <Text style={styles.dataDate}>{product.bill_date}</Text>
                    <Text style={styles.dataDate}>{product.stock_date}</Text>
                    <Text style={styles.dataReceipt}>{product.bill_no}</Text>
                    <Text style={styles.partyname}>{product.party_details.party_name}</Text>
                    <Text style={styles.dataSubTotal}>{product.sum_total}</Text>
                    <Text style={styles.dataTax}>{gst}</Text>
                    <Text style={styles.dataend}>{product.total}</Text>
                  </View>
                );
              })}subTotal
            </View>
          </View>
          <View style={styles.lastpage}>
            <View style={styles.lastone}>
              <Text style={styles.totaledit}>Total</Text>
            </View>
            <View style={styles.lastone}>
              <Text style={styles.suptotal}>{subTotal.toFixed(2)}</Text>
            </View>
            <View style={styles.lastone}>
              <Text style={styles.supgst}> {subgst.toFixed(2)}</Text>
            </View>
            <View style={styles.lastone}>
              <Text style={styles.supgrandtotal}> {subgrandtotal.toFixed(2)}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default PurchaseReport