import React from 'react'
import { PDFViewer } from '@react-pdf/renderer';
import SaleRep from './SaleRep';
const SalePreview = () => 
     (
    <PDFViewer height={"1000"} width={"100%"}>
        <SaleRep/>
    </PDFViewer>
  )


export default SalePreview