import React, { useState, useEffect } from 'react';
import './SideBar.css';
import { NavLink, Route, Routes } from 'react-router-dom';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import {
  MdOutlineHome,
  MdOutlinePerson,
  MdBusiness,
  MdOutlineAddShoppingCart,
  MdOutlineCategory,
  MdOutlineContentPasteGo,
  MdOutlineAddCard,
  MdOutlineFeed,
  MdMenuBook
} from 'react-icons/md';
import { PiUsers } from 'react-icons/pi';
import { LuDot } from 'react-icons/lu';
import { Collapse } from 'react-bootstrap';
import { Header } from './Header';
import Dashboard from '../pages/Dashboard';
import ProductList from '../pages/ProductList';
import CategoryList from '../pages/CategoryList';
import UnitList from '../pages/UnitList';
import ItemCreation from '../pages/ItemCreation';
import Party from '../pages/PurchaseParty';
import SalesParty from '../pages/SalesParty';
import PurchaseBill from '../pages/PurchaseBilllisting';
import PayOut from '../pages/PayOut';
import { PurchaseBillEntry } from '../pages/PurchaseBills';
import SalesInvoice from '../pages/SalesInvoice';
import { EstimationEntry, SalesInvoiceEntry } from '../pages/SalesBills';
import Estimation from '../pages/Estimation';
import PayIn from '../pages/PayIn';
import Expenses from '../pages/Expenses';
import User from '../pages/User';
import Company from '../pages/Company';
import DayBook from '../pages/DayBook';
import Reports from '../pages/Reports';

const SideBar = () => {
  // Initialize openMenu state from local storage or set as empty object if none found
  const [openMenu, setOpenMenu] = useState(JSON.parse(localStorage.getItem('openMenu')) || {});

  // Function to handle submenu clicks
  const handleMenuClick = (menuIndex) => {
    // Set state to keep only the clicked submenu open and close others
    setOpenMenu((prevOpenMenu) => {
      const newOpenMenu = {};
      // Close all submenus
      for (let key in prevOpenMenu) {
        newOpenMenu[key] = false;
      }
      // Open the clicked submenu
      newOpenMenu[menuIndex] = !prevOpenMenu[menuIndex];
      return newOpenMenu;
    });
  };

  // Save openMenu state to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('openMenu', JSON.stringify(openMenu));
  }, [openMenu]);

  return (
    <div className=''>
      <Header />
      <aside id='side-bar' className='side-bar'>
        <div className='list-group'>
          <ul>
            <li>
              <NavLink to='/dashboard' className='nav-link'>
                <span className='list-icon'><MdOutlineHome /></span>
                <span class='list-text'>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/console/user' className='nav-link'>
                <span className='list-icon'><MdOutlinePerson /></span>
                <span class='list-text'>User & Access</span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/console/company' className='nav-link'>
                <span className='list-icon'><MdBusiness /></span>
                <span class='list-text'>Company</span>
              </NavLink>
            </li>
            {/* Parties submenu */}
            <li>
              <div className='sub-menu nav-link' onClick={() => handleMenuClick(0)}>
                <span className='list-icon'><PiUsers /></span>
                <span class='list-text'>Parties</span>
                <span className={`list-icon arrow ${openMenu[0] ? 'rotate' : ''}`}><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={openMenu[0]}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to='/console/party/purchase' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Purchase</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/console/party/sales' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Sales</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            {/* Items submenu */}
            <li>
              <div className='sub-menu nav-link' onClick={() => handleMenuClick(1)}>
                <span className='list-icon'><MdOutlineCategory /></span>
                <span class='list-text'>Items</span>
                <span className={`list-icon arrow ${openMenu[1] ? 'rotate' : ''}`}><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={openMenu[1]}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to='/console/item/category' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Category</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/console/item/unit' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Unit</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/console/item/product' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Products</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            {/* Purchase submenu */}
            <li>
              <div className='sub-menu nav-link' onClick={() => handleMenuClick(2)}>
                <span className='list-icon'><MdOutlineAddShoppingCart /></span>
                <span class='list-text'>Purchase</span>
                <span className={`list-icon arrow ${openMenu[2] ? 'rotate' : ''}`}><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={openMenu[2]}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to='/console/purchase/bill' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Bill</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/console/purchase/payout' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Pay Out</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            {/* Sales submenu */}
            <li>
              <div className='sub-menu nav-link' onClick={() => handleMenuClick(3)}>
                <span className='list-icon'><MdOutlineContentPasteGo /></span>
                <span class='list-text'>Sales</span>
                <span className={`list-icon arrow ${openMenu[3] ? 'rotate' : ''}`}><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={openMenu[3]}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to='/console/sales/invoice' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Invoice</span>
                    </NavLink>
                  </li>
                  <li>
                    {/* <NavLink to='/console/sales/estimation' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Estimation</span>
                    </NavLink> */}
                  </li>
                  <li>
                    <NavLink to='/console/sales/payin' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Pay In</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            {/* Expense, DayBook, and Report links */}
            <li>
              <NavLink to='/console/expense' className='nav-link'>
                <span className='list-icon'><MdOutlineAddCard /></span>
                <span class='list-text'>Expense</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to='/console/daybook' className='nav-link'>
                <span className='list-icon'><MdOutlineFeed /></span>
                <span class='list-text'>DayBook</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink to='/console/report' className='nav-link'>
                <span className='list-icon'><MdMenuBook /></span>
                <span class='list-text'>Report</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
      <div id='main'>
        <Routes>
          {/* Define routes for different pages */}
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/console/user' element={<User />} />
          <Route path='/console/company' element={<Company />} />
          <Route path='/console/party/purchase' element={<Party />} />
          <Route path='/console/party/sales' element={<SalesParty />} />
          <Route path='/console/item/product' element={<ProductList />} />
          <Route path='/console/item/category' element={<CategoryList />} />
          <Route path='/console/item/unit' element={<UnitList />} />
          <Route path='/console/item/create' element={<ItemCreation />} />
          <Route path='/console/purchase/bill' element={<PurchaseBill />} />
          <Route path='/console/purchase/payout' element={<PayOut />} />
          <Route path='/console/purchase/bill/create' element={<PurchaseBillEntry />} />
          <Route path='/console/sales/invoice' element={<SalesInvoice />} />
          <Route path='/console/sales/invoice/create' element={<SalesInvoiceEntry />} />
          <Route path='/console/sales/estimation' element={<Estimation />} />
          <Route path='/console/sales/estimation/create' element={<EstimationEntry />} />
          <Route path='/console/sales/payin' element={<PayIn />} />
          <Route path='/console/expense' element={<Expenses />} />
          <Route path='/console/daybook' element={<DayBook />} />
          <Route path='/console/report' element={<Reports />} />
        </Routes>
      </div>
    </div>
  );
};

export default SideBar;
