import React from 'react'
import { PDFViewer } from '@react-pdf/renderer';
import PurchaseReceipt from './PurchaseReceipt';
const PurchaseBillReceipt = () =>
(
    <PDFViewer height={"1000"} width={"100%"}>
        <PurchaseReceipt />
    </PDFViewer>
)

export default PurchaseBillReceipt