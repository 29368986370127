import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Modal, Tab, Tabs, Offcanvas } from 'react-bootstrap';
import { Buttons, ClickButton } from '../components/Buttons';
import { TableUI } from '../components/Tables'
import { PageHead, PageTitle } from '../components/PageTitle';
import { FaMagnifyingGlass } from "react-icons/fa6";
import { Calender, DropDown, DropDownUI, TextInputForm } from '../components/Forms';
import { Navigate } from 'react-router-dom';
import { MdOutlineClose } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment/moment'
import Pagnation from '../components/Pagnation';
const PartyHistroy = ["S.No", "Type", "Date", "Receipt No", "amount",]
const PurchasePartyTable = ["Name", 'Type', "Action"]
const DropList = [
  {
    value: 'DR',
    label: 'DR'
  },
  {
    value: 'CR',
    label: 'CR'
  }
]
const Party = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [show, setShow] = useState(false);
  const [openFilter, setFilter] = useState(false);
  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    setPurchase(null)
    setFormData({
      party_id: '',
      phone_number: '',
      party_name: '',
      mobile_number: '',
      alter_number: '',
      email: '',
      company_name: '',
      gst_no: '',
      password: '',
      address: '',
      opening_balance: '',
      opening_date: '',
      ac_type: ''
    })
    setShow(true);
  }
  const closeFilter = () => setFilter(false);
  const showFilter = () => setFilter(true);
  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     setLoading(false)
  //   })
  // })
  const [crtPurchase, setPurchase] = useState(null);
  const handleEditClick = (rowdata) => {
    setPurchase(rowdata)
    setShow(true)

  };
  const [formData, setFormData] = useState({
    party_id: '',
    phone_number: '',
    party_name: '',
    mobile_number: '',
    city: '',
    state: '',
    alter_number: '',
    email: '',
    company_name: '',
    gst_no: '',
    password: '',
    address: '',
    opening_balance: '',
    opening_date: '',
    ac_type: ''
  });
  console.log("formData",formData);
  const [userData, setUserData] = useState([]);
  console.log("userData",userData);
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    if (crtPurchase != null) {
      setPurchase({
        ...crtPurchase,
        [fieldName]: value
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: value
      });
    }
  };
  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: moment(value).format("YYYY-MM-DD") // Update the specified field in formData with the provided value
    });
  };
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  //   return () => clearTimeout(timer);
  // }, []);
  const [clickedData, setClickedData] = useState(null);

  const handleClick = (data) => {
    setClickedData(data);
  };
  const [searchText, setSearchText] = useState('');
  const handleSearch = (value) => {
    setSearchText(value);
    //console.log(value);
  };

  const fetchData = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://billing.barbikan.in/purchaseparty/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: searchText
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log('siva', companyId);
      setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data);
        console.log(setUserData)
        if (responseData.data.length > 0) {
          setClickedData(responseData.data[0]);
        }
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, [searchText]);
  const [showAlert, setShowAlert] = useState(false);
  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",

    });
  }
  const handleSubmit = async () => {
    try {
      if (formData.party_name === '') {
        errorAlert("Party is Must");
      }
      else {
        const companyId = localStorage.getItem('companyId');
        const response = await fetch('https://billing.barbikan.in/purchaseparty/create/' + companyId, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
        console.log(formData);
        const responseData = await response.json();

        console.log(responseData);

        if (responseData.status === 200) {
          fetchData();
          successAlert(responseData.msg)
          setTimeout(() => {
            handleClose(); // Close modal after 10 seconds
          }, 2000);
        }
        else if (responseData.status === 400) {
          toast.error('Missing required fields!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else {
          setShowAlert(true);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://billing.barbikan.in/purchaseparty/update/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ // Include the company ID in the request
          party_id: crtPurchase.party_id,
          party_name: crtPurchase.party_name,
          mobile_number: crtPurchase.mobile_number,
          alter_number: crtPurchase.alter_number,
          email: crtPurchase.email,
          city: crtPurchase.city,
          state: crtPurchase.state,
          company_name: crtPurchase.company_name,
          gst_no: crtPurchase.gst_no,
          address: crtPurchase.address,
          opening_balance: crtPurchase.opening_balance,
          opening_date: crtPurchase.opening_date,
          ac_type: crtPurchase.ac_type,

        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update company');
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        fetchData();
        toast.success('Purchase party updated successfully!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          handleClose();
        }, 2000);

        // Navigate to the user list page after a delay

      } else {
        console.error(responseData.msg || 'Unknown error occurred during update');
      }
    } catch (error) {
      console.error('Error updating product:', error.msg);
    }

    setLoading(false);
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem('session');
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking session:', error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {

    return <Navigate to="/login" replace />;
  }
  return (
    <div>
      <div>
        <Container fluid >
          <Row>
            <Col lg="4" className='py-1'>
              <div className='main my-2'>
                {/* <div className='fixed d-flex align-item-baseline justify-content-between'>
                  <div>
                   
                  </div>
                  <div className='text-end py-2'><Buttons lable={<> Add New</>} onClick={handleShow} /></div>
                </div> */}
                <Row>
                  <Col lg="7">
                    <div className='mx-2'>
                      {/* <Buttons lable={<>Filter</>} onClick={showFilter} /> */}
                      <TextInputForm placeholder={"Name"}
                        onChange={(e) => handleSearch(e.target.value)}
                        prefix_icon={<FaMagnifyingGlass />}
                        labelname={"Search"}> </TextInputForm>
                    </div>
                  </Col>
                  <Col lg="5" className='align-item-baseline d-flex justify-content-end'>
                    <div><Buttons lable={<> Add New</>} onClick={handleShow} /></div>
                  </Col>
                </Row>
                <div className='py-2 c-table'>
                  <TableUI headers={PurchasePartyTable} body={userData} type="partypurches" handleEditClick={handleEditClick} onClick={handleClick} onDelete={fetchData} />
                </div>
              </div>
            </Col>
            <Col lg='8'>
              <div className='my-2'>
                <div className='main'>
                  {clickedData && (
                    <div >
                      <div className='d-flex justify-content-between mb-2'>
                        <div>Name : {clickedData.party_name}</div>
                        <div><span>MobileNumber : {clickedData.mobile_number}</span></div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <div><span>AccountType : {clickedData.ac_type}</span></div>
                        <div><span>Amount : {clickedData.opening_balance}</span></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='my-2'>
                <div className='main'>
                  <Row>
                    <Col lg='4'>
                      <PageTitle PageTitle={<>Transactions</>} />
                    </Col>
                    <Col lg="8">

                    </Col>
                  </Row>
                  <Offcanvas show={openFilter} onHide={closeFilter} placement='end'>
                    <Offcanvas.Body>
                      <Row>
                        <Col lg="6">
                          <PageTitle PageTitle={<>Purchase Party</>} />
                        </Col>
                        <Col lg="6" className='align-self-center'>
                          <div className='text-end'>
                            <Buttons onClick={closeFilter} lable={<><MdOutlineClose /></>}></Buttons>
                          </div>
                        </Col>
                      </Row>
                      <div className='py-3'>
                        <TextInputForm labelname={<>Receipt No.</>} />
                      </div>
                      <div className='py-3'>
                        <DropDownUI labelname={"Select Purchase Party"} />
                      </div>
                      <div className='py-3'>
                        <DropDownUI labelname={" Select Type"} />
                      </div>
                      <Row>
                        <Col lg="6" className='align-self-center'>
                          <div>
                            <Calender labelname={<>From</>} />
                          </div>
                        </Col>
                        <Col lg="6" className='align-self-center'>
                          <div>
                            <Calender labelname={<>To</>} />
                          </div>
                        </Col>
                        <Col lg="6" className='align-self-center'>
                          <div className='my-5 text-center'>
                            <Buttons lable={"Apply"}></Buttons>
                          </div>
                        </Col>
                        <Col lg="6" className='align-self-center'>
                          <div className='my-5 text-center'>
                            <Buttons lable={"Clear"}></Buttons>
                          </div>
                        </Col>
                      </Row>
                    </Offcanvas.Body>
                  </Offcanvas>
                  <div className='py-2 c-table table-scroll'>
                    <TableUI headers={PartyHistroy} body={clickedData != null ? clickedData.transactions : []} type="partypurchestransactions" pageview={"yes"}></TableUI></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            size='lg'
          >
            <Modal.Header>
              <PageHead pagehead={<> Purchase Party</>} onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col lg="4" className='py-3'>
                    <TextInputForm
                      placeholder={'Company Name'}
                      labelname={'Company Name'}
                      name='company_name'
                      value={crtPurchase != null ? crtPurchase.company_name : formData.company_name}
                      onChange={(e) => handleChange(e, 'company_name')}
                    ></TextInputForm>
                  </Col>
                  <Col lg="4" className='py-3'>
                    <TextInputForm
                      placeholder={'Party Name'}
                      labelname={'Party Name'}
                      name='party_name'
                      value={crtPurchase != null ? crtPurchase.party_name : formData.party_name}
                      onChange={(e) => handleChange(e, 'party_name')}
                    ></TextInputForm>
                  </Col>
                  <Col lg="4" className='py-3'>
                    <TextInputForm labelname={"GST No"}
                      placeholder={'GST No'}
                      name='gst_no'
                      value={crtPurchase != null ? crtPurchase.gst_no : formData.gst_no}
                      onChange={(e) => handleChange(e, 'gst_no')} />
                  </Col>
                  <Col lg="4" className='py-3'>
                    <TextInputForm
                      placeholder={'MobileNumber'}
                      labelname={'MobileNumber'}
                      name='mobile_number'
                      value={crtPurchase != null ? crtPurchase.mobile_number : formData.mobile_number}
                      onChange={(e) => handleChange(e, 'mobile_number')}
                    ></TextInputForm>
                  </Col>
                </Row>
              </Container>
              <Tabs
                defaultActiveKey="address"
                className="my-3"
              >
                <Tab eventKey="address" title="Address">
                  <Container>
                    <Row>
                      <Col lg="6" className='py-3'>
                        <TextInputForm
                          placeholder={'Alter Number'}
                          labelname={'Alter Number'}
                          name='alter_number'
                          value={crtPurchase != null ? crtPurchase.alter_number : formData.alter_number}
                          onChange={(e) => handleChange(e, 'alter_number')}
                        />
                      </Col>
                      <Col lg="6" className='py-3'>
                        <TextInputForm
                          placeholder={'E Mail'}
                          labelname={'E Mail'}
                          name='email '
                          value={crtPurchase != null ? crtPurchase.email : formData.email}
                          onChange={(e) => handleChange(e, 'email')}
                        />
                      </Col>
                      
                      <Col lg="6" className='py-3'>
                        <TextInputForm
                          placeholder={'State'}
                          labelname={'State'}
                          name='state'
                          value={crtPurchase != null ? crtPurchase.state : formData.state}
                          onChange={(e) => handleChange(e, 'state')}
                        />
                      </Col>
                      <Col lg="6" className='py-3'>
                        <TextInputForm
                          placeholder={'City'}
                          labelname={'City'}
                          name='city'
                          value={crtPurchase != null ? crtPurchase.city : formData.city}
                          onChange={(e) => handleChange(e, 'city')}
                        />
                      </Col>
                      <Col lg='6' className='py-3'>
                        <label className='pb-2'>Address</label>
                        <textarea className='form-cntrl form-control'
                          placeholder={'Address'}
                          labelname={'Address'}
                          name='address '
                          value={crtPurchase != null ? crtPurchase.address : formData.address}
                          onChange={(e) => handleChange(e, 'address')}
                        />
                      </Col>
                      {/* <Col lg='6' className='py-3'>
                        <label className='pb-2'>Shipping Address</label>
                        <textarea className='form-cntrl form-control' />
                      </Col> */}
                    </Row>
                  </Container>
                </Tab>
                <Tab eventKey="credit-balance" title="Credit & Balance">
                  <Container>
                    <Row>

                      {/* <Col lg="4" className='py-3'>
                        <div className='w-100'>
                          <Calender setLabel={(date) => setLabel(date, 'opening_date')} selectedDate={crtPurchase != null ? crtPurchase.opening_date : formData.opening_date} calenderlabel="OpeaningDate" />
                        </div>
                      </Col> */}
                      <Col lg="4" className='py-3'>
                        <div className='w-100'>
                          <DropDownUI
                            optionlist={DropList}
                            placeholder='Category'
                            labelname='Category'
                            name='ac_type'
                            value={crtPurchase != null ? crtPurchase.ac_type : formData.ac_type}
                            onChange={(updatedFormData) => {
                              if (crtPurchase !== null) {
                                setPurchase({ ...crtPurchase, ac_type: updatedFormData.ac_type })
                              } else {
                                setFormData({ ...formData, ac_type: updatedFormData.ac_type })
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="4" className='py-3'>
                        <TextInputForm
                          placeholder={'Opening Balance'}
                          labelname={'Opening Balance'}
                          name='opening_balance'
                          value={crtPurchase != null ? crtPurchase.opening_balance : formData.opening_balance}
                          onChange={(e) => handleChange(e, 'opening_balance')}
                        />
                      </Col>
                      {/* <Col lg='12' className='py-3'>
                        <PageTitle PageTitle={"Credit Limit"} />
                        <Row>
                          <Col lg='3' className='py-3 align-self-center'>
                            <div className='text-center'>No Limit</div>
                          </Col>
                          <Col lg='3' className='py-3 align-self-center'>
                            <div>
                              <Buttons type="button" onClick={() => SetLimit(!Limit)} lable={Limit === false ? 'Set Limit' : 'No Limit'} />
                            </div>
                          </Col>
                          <Col lg='6' className='py-3 align-self-center'>
                            <div >
                              {Limit && <div>
                                <div> </div>
                                <TextInputForm labelname={"Limit"} />
                              </div>}
                            </div>
                          </Col>
                        </Row>
                      </Col> */}
                    </Row>
                  </Container>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <div className='text-center py-4'>
                {crtPurchase != null ? (
                  <>
                    <ToastContainer
                      position="top-center"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                    <span className='mx-2'>
                      <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

                    </span>

                    <span className='mx-2'>
                      <ClickButton label={<>Cancel</>} onClick={() => { handleClose() }}></ClickButton>
                    </span>
                  </>

                ) : (
                  <>
                    <ToastContainer
                      position="top-center"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                    <span className='mx-2'>
                      <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>
                    </span>

                    <span className='mx-2'>
                      <ClickButton label={<>Cancel</>} onClick={() => { handleClose() }}></ClickButton>
                    </span>
                  </>
                )}
              </div>
            </Modal.Footer>
          </Modal>
        </>
      </div>

    </div>
  )
}

export default Party