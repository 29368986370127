import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Col, Row, Offcanvas, Modal, ModalBody } from 'react-bootstrap';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Buttons } from '../components/Buttons';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { TableUI } from '../components/Tables';
import { PageHead, PageTitle } from '../components/PageTitle';
import { MdOutlineClose } from "react-icons/md";
import Pdf from './Pdf';
import '../components/Table.css';
import moment from 'moment/moment';
import { Calender, DropDownUI, TextInputForm } from '../components/Forms';

const ProductHistory = ["S.NO", "Date", "Receipt No.", "Name", "Amount", "Received", "Status"];

const SalesInvoice = () => {
    const location = useLocation();
    const { type, rowData } = location.state || {};

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [openFilter, setFilter] = useState(false);
    const closeFilter = () => setFilter(false);
    const showFilter = () => setFilter(true);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [printrow, setPrintRow] = useState([]);
    const handleClose = () => {
        setShow(false);
        setSelectedOption('');
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const [filterData, setFilterData] = useState({
        search_text: '',
        party_id: '',
        from_date: '',
        to_date: ''
    });

    const clearFilter = () => {
        setFilterData({
            search_text: '',
            party_id: '',
            from_date: '',
            to_date: ''
        });
    };

    const handleChange = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;
        setFilterData({
            ...filterData,
            [fieldName]: value
        });
    };

    const setLabel = (value, field) => {
        setFilterData({
            ...filterData,
            [field]: moment(value).format("YYYY-MM-DD")
        });
    };

    const [partySalesData, setPartySalesData] = useState([]);
    const fetchPartySalesData = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            const response = await fetch('https://billing.barbikan.in/salesparty/list/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    search_text: ''
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const responseData = await response.json();
            setLoading(false);
            if (responseData.status === 200) {
                setPartySalesData(responseData.data);
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error.message);
        }
    };

    useEffect(() => {
        fetchPartySalesData();
    }, []);

    const [userData, setUserData] = useState([]);
    console.log('userData', userData);
    const fetchData = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            const response = await fetch('https://billing.barbikan.in/saleinvoice/list/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    search_text: filterData.search_text,
                    party_id: filterData.party_id,
                    from_date: filterData.from_date,
                    to_date: filterData.to_date
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const responseData = await response.json();
            setLoading(false);
            if (responseData.status === 200) {
                setUserData(responseData.data);
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, [filterData]);

    const PrintModal = (rowData) => {
        setShow(true);
        setPrintRow(rowData);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleApiCall = (printrow, selectedOption) => {
        openPrintView(printrow, selectedOption);
    };

    const openPrintView = async (printrow, type) => {
        const documents = [];
        if (type === 'Original' || type === 'Duplicate' || type === 'Triplicate') {
            const originalBlob = await pdf(<Pdf data={printrow} type="Original" />).toBlob();
            documents.push({ url: URL.createObjectURL(originalBlob), type: 'Original' });
        }
        if (type === 'Duplicate' || type === 'Triplicate') {
            const duplicateBlob = await pdf(<Pdf data={printrow} type="Duplicate" />).toBlob();
            documents.push({ url: URL.createObjectURL(duplicateBlob), type: 'Duplicate' });
        }
        if (type === 'Triplicate') {
            const triplicateBlob = await pdf(<Pdf data={printrow} type="Triplicate" />).toBlob();
            documents.push({ url: URL.createObjectURL(triplicateBlob), type: 'Triplicate' });
        }

        documents.forEach(doc => {
            const printWindow = window.open(doc.url, '_blank');
            printWindow.onload = () => {
                printWindow.print();
                URL.revokeObjectURL(doc.url);
            };
        });
    };

    useEffect(() => {
        if (selectedOption !== '') {
            handleApiCall(printrow, selectedOption);
        }
    }, [selectedOption, printrow]);

    const [isLoggedIn, setIsLoggedIn] = useState(true);
    useEffect(() => {
        const checkSession = async () => {
            try {
                const session = localStorage.getItem('session');
                if (!session) {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                console.error('Error checking session:', error.message);
                setIsLoggedIn(false);
            }
        };
        checkSession();
    }, []);

    if (!isLoggedIn) {
        return <Navigate to="/login" replace />;
    }

    return (
        <div>
            <Container fluid>
                <div className='main p-3 px-2 my-3'>
                    <div>
                        <Navbar>
                            <Navbar.Brand><PageTitle PageTitle={"Sale Invoice"} /></Navbar.Brand>
                            <Nav className="ms-auto">
                                <span className='mx-2 py-2'><Buttons lable={<>Filter</>} onClick={showFilter} /></span>
                            </Nav>
                        </Navbar>
                    </div>
                    <Offcanvas show={openFilter} onHide={closeFilter} placement='end'>
                        <Offcanvas.Body>
                            <Row>
                                <Col lg="6">
                                    <PageTitle PageTitle={<>Sales Invoice</>} />
                                </Col>
                                <Col lg="6" className='align-self-center'>
                                    <div className='text-end'>
                                        <Buttons onClick={closeFilter} lable={<><MdOutlineClose /></>}></Buttons>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <div className='py-3'>
                                    <TextInputForm
                                        labelname={'Search Receipt'}
                                        placeholder={'Search Receipt'}
                                        name='search_text'
                                        value={filterData.search_text}
                                        onChange={(e) => handleChange(e, 'search_text')}
                                    ></TextInputForm>
                                </div>
                                <div className='py-3'>
                                    <DropDownUI
                                        optionlist={partySalesData.map(user => ({
                                            value: user.party_id,
                                            label: user.party_name
                                        }))}
                                        placeholder='PartyName'
                                        labelname='PartyName'
                                        name='party_id'
                                        value={filterData.party_id}
                                        onChange={(updatedFormData) => setFilterData({ ...filterData, party_id: updatedFormData.party_id })}
                                    />
                                </div>
                                <Row>
                                    <Col lg="6" className='align-self-center'>
                                        <div>
                                            <Calender setLabel={(date) => setLabel(date, 'from_date')} selectedDate={filterData.from_date} calenderlabel="From Date" />
                                        </div>
                                    </Col>
                                    <Col lg="6" className='align-self-center'>
                                        <div>
                                            <Calender setLabel={(date) => setLabel(date, 'to_date')} selectedDate={filterData.to_date} calenderlabel="To Date" />
                                        </div>
                                    </Col>
                                    <Col lg="6" className='align-self-center'>
                                        <div className='my-5 text-center'>
                                            <Buttons lable={"Apply"}></Buttons>
                                        </div>
                                    </Col>
                                    <Col lg="6" className='align-self-center'>
                                        <div className='my-5 text-center'>
                                            <Buttons lable={"Clear"} onClick={clearFilter}></Buttons>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
                <div className='main p-3'>
                    <div className='py-1'>
                        <Navbar>
                            <Container fluid className='px-0'>
                                <Navbar.Brand><PageTitle PageTitle={"Transactions"} /></Navbar.Brand>
                                <Nav className="ms-auto">
                                    <span className='mx-2 py-2'> <Buttons lable={<>Add New</>} onClick={() => navigate("/console/sales/invoice/create")} /></span>
                                </Nav>
                            </Container>
                        </Navbar>
                    </div>
                    <div className='py-2 c-table table-scroll'>
                        <TableUI headers={ProductHistory} body={userData} type='salesinvoice' handleEditClick={PrintModal} pageview={"yes"} onDelete={fetchData}></TableUI>
                    </div>
                </div>
            </Container>
            <Modal size='xs' show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <PageHead pagehead={<>Print Type</>} onClick={handleClose} />
                </Modal.Header>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col md={4}>
                                <input
                                    type="radio"
                                    id="original"
                                    name="fav_language"
                                    value="Original"
                                    onChange={(e) => handleOptionChange(e)}
                                />
                                <label htmlFor="original">Original</label>
                            </Col>
                            <Col md={4}>
                                <input
                                    type="radio"
                                    id="duplicate"
                                    name="fav_language"
                                    value="Duplicate"
                                    onChange={(e) => handleOptionChange(e)}
                                />
                                <label htmlFor="duplicate">Duplicate</label>
                            </Col>
                            <Col md={4}>
                                <input
                                    type="radio"
                                    id="triplicate"
                                    name="fav_language"
                                    value="Triplicate"
                                    onChange={(e) => handleOptionChange(e)}
                                />
                                <label htmlFor="triplicate">Triplicate</label>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default SalesInvoice;
