import React, { useState, useEffect } from 'react'
import { Container, Nav, Navbar, Modal, Col, Row, Table, Offcanvas } from 'react-bootstrap'
import { Buttons, ClickButton } from '../components/Buttons'
import { TableUI } from '../components/Tables'
import { PageHead, PageTitle } from '../components/PageTitle'
import { Calender, DropDownUI, TextInputForm } from '../components/Forms'
import PropagateLoader from "react-spinners/PropagateLoader";
import { useLocation, Navigate } from 'react-router-dom';
import { MdOutlineClose } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment/moment'
const ProductHistory = ["S No", "Date", "Receipt No.", "Name", "Total", "Status"]
const DropList = [
    {
        value: 'Admin',
        label: 'Admin'
    },
    {
        value: 'Super Admin',
        label: 'Super Admin'
    },
    {
        value: 'Staff',
        label: 'Staff'
    }
]
const user = [
    {
        "voucher_date": "25852",
        "party_id": "85963",
        "paid": "200"
    }
]
const PayOut = () => {
    const [openFilter, setFilter] = useState(false);
    const closeFilter = () => setFilter(false);
    const showFilter = () => setFilter(true);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setCrtPayOut(null)
        setFormData({
            party_id: '',
            voucher_date: '',
            paid: '',
        })
        setShow(true);
    }
    const [crtPayOut, setCrtPayOut] = useState(null);
    const handleEditClick = (rowdata) => {
        setCrtPayOut(rowdata)
        setShow(true)

    };
    const [formData, setFormData] = useState({
        party_id: '',
        voucher_date: '',
        paid: '',
    });
    const location = useLocation();
    const { type, rowData } = location.state || {};
    const [filterData, setFilterData] = useState({
        search_text: '',
        party_id: '',
        from_date: '',
        to_date: ''
    })
    console.log('kanna', filterData);
    const handleChanges = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;
        setFilterData({
            ...filterData,
            [fieldName]: value
        });
    };
    const handleChange = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;
        if (crtPayOut != null) {
            setCrtPayOut({
                ...crtPayOut,
                [fieldName]: value
            });
        } else {
            setFormData({
                ...formData,
                [fieldName]: value
            });
        }
    };

    const setLabel = (value, field) => {
        setFormData({
            ...formData,
            [field]: moment(value).format("YYYY-MM-DD") // Update the specified field in formData with the provided value
        });
    };
    const setLabell = (value, field) => {
        setFilterData({
            ...filterData,
            [field]: moment(value).format("YYYY-MM-DD") // Update the specified field in formData with the provided value
        });
    };
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    const [purchasepartyData, setPurchasepartyData] = useState([]);

    const fetchPartyData = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            const response = await fetch('https://billing.barbikan.in/purchaseparty/list/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    search_text: ''
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const responseData = await response.json();
            console.log(responseData);
            setLoading(false);
            if (responseData.status === 200) {
                setPurchasepartyData(responseData.data);
                console.log(setPurchasepartyData)
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error.message);
        }
    };
    useEffect(() => {
        fetchPartyData(); // Call fetchData directly in useEffect
    }, []);
    const [userData, setUserData] = useState([]);
    console.log('Data', userData)
    const fetchData = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            const response = await fetch('https://billing.barbikan.in/payout/list/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    search_text: filterData.search_text,
                    party_id: filterData.party_id,
                    from_date: filterData.from_date,
                    to_date: filterData.to_date
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const responseData = await response.json();
            console.log(responseData);
            setLoading(false);
            if (responseData.status === 200) {
                // Set userData to the array of purchase parties
                setUserData(responseData.data);
                console.log(responseData)
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);

        }
    };
    useEffect(() => {


        fetchData(); // Call fetchData directly in useEffect
    }, [filterData]);
    const [showAlert, setShowAlert] = useState(false);
    const errorAlert = (input) => {
        toast.error(input, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    const successAlert = (success) => {
        toast.success(success, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",

        });
    }
    const clearFilter = () => {
        setFilterData({
            search_text: '',
            party_id: '',
            from_date: '',
            to_date: ''
        })
    }
    const handleSubmit = async () => {
        try {
            console.log(formData)
            const companyId = localStorage.getItem('companyId');
            const response = await fetch('https://billing.barbikan.in/payout/create/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            console.log(formData);
            const responseData = await response.json();

            console.log(responseData);

            if (responseData.status === 200) {

                setFormData({
                    party_id: '',
                    voucher_date: '',
                    paid: '',
                })
                fetchData();
                successAlert(responseData.msg)
                setTimeout(() => {
                    handleClose(); // Close modal after 10 seconds
                }, 2000);
            }
            else if (responseData.status === 400) {
                toast.error('Missing required fields!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            else {
                setShowAlert(true);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleUpdateSubmit = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            const response = await fetch('https://billing.barbikan.in/payout/update/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ // Include the company ID in the request
                    payout_id: crtPayOut.payout_id,
                    paid: crtPayOut.paid,
                    voucher_date: crtPayOut.voucher_date,
                    party_id: crtPayOut.party_id,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update company');
            }

            const responseData = await response.json();
            console.log(responseData);

            if (responseData.status === 200) {
                fetchData();
                toast.success('User updated successfully!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    handleClose();
                }, 2000);

                // Navigate to the user list page after a delay

            } else {
                console.error(responseData.msg || 'Unknown error occurred during update');
            }
        } catch (error) {
            console.error('Error updating product:', error.msg);
        }

        setLoading(false);
    };
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    useEffect(() => {
        const checkSession = async () => {
            try {
                // Check session logic here, e.g., fetch from local storage or server
                const session = localStorage.getItem('session');
                if (!session) {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                console.error('Error checking session:', error.message);
                setIsLoggedIn(false);
            }
        };
        checkSession();
    }, []);
    if (!isLoggedIn) {

        return <Navigate to="/login" replace />;
    }
    return (
        <div >
            <div>
                <Container fluid>
                    <div>
                        <div className='main p-3 my-2'>
                            <div>
                                <Navbar >
                                    <Navbar.Brand><PageTitle PageTitle={"Pay Out"} /></Navbar.Brand>
                                    <Nav className="ms-auto">
                                        {/* <span className='mx-2 py-2'> <Buttons lable={<>PDF</>} /></span> */}
                                        <span className='mx-2 py-2'><Buttons lable={<>Filter</>} onClick={showFilter} /></span>
                                    </Nav>
                                </Navbar>
                            </div>
                            <Offcanvas show={openFilter} onHide={closeFilter} placement='end'>
                                <Offcanvas.Body>
                                    <Row>
                                        <Col lg="6">
                                            <PageTitle PageTitle={<>Purchase Bill</>} />
                                        </Col>
                                        <Col lg="6" className='align-self-center'>
                                            <div className='text-end'>
                                                <Buttons onClick={closeFilter} lable={<><MdOutlineClose /></>}></Buttons>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div>
                                        <div className='py-3'>

                                            <TextInputForm
                                                labelname={'Search Receipt'}
                                                placeholder={'Search Receipt'}
                                                name='search_text'
                                                value={filterData.search_text}
                                                onChange={(e) => handleChanges(e, 'search_text')}
                                            ></TextInputForm>
                                        </div>
                                        <div className='py-3'>
                                            <DropDownUI
                                                optionlist={purchasepartyData.map(user => ({
                                                    value: user.party_id,
                                                    label: user.party_name
                                                }))}
                                                placeholder='Select Party'
                                                labelname='Select Party'
                                                name='party_id'
                                                value={filterData.party_id}
                                                onChange={(updatedFormData) => setFilterData({ ...filterData, party_id: updatedFormData.party_id })}
                                            />
                                        </div>
                                        <Row>
                                            <Col lg="6" className='align-self-center'>
                                                <div>
                                                    <Calender setLabel={(date) => setLabell(date, 'from_date')} selectedDate={filterData.from_date} calenderlabel="From Date" />
                                                </div>
                                            </Col>
                                            <Col lg="6" className='align-self-center'>
                                                <div>
                                                    <Calender setLabel={(date) => setLabell(date, 'to_date')} selectedDate={filterData.to_date} calenderlabel="To Date" />
                                                </div>
                                            </Col>

                                            <Col lg="12" className='align-self-center'>
                                                <div className='my-5 text-center'>
                                                    <Buttons lable={"Clear"} onClick={clearFilter}></Buttons>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </div>
                        <div className='main p-3'>
                            <div className='py-1'>
                                <Navbar >
                                    <Navbar.Brand><PageTitle PageTitle={"Transactions"} /></Navbar.Brand>
                                    <Nav className="ms-auto">
                                        <span className='mx-2 py-2'> <Buttons lable={<>Add New</>} onClick={handleShow} /></span>
                                    </Nav>
                                </Navbar>
                            </div>
                            <div className='py-2 c-table table-scroll'>
                                <TableUI headers={ProductHistory} body={userData} type='purchasepayout' pageview={"yes"} handleEditClick={handleEditClick} onDelete={fetchData}></TableUI>
                            </div>

                        </div>
                    </div>
                </Container>
            </div>
            <>
                <Modal show={show} onHide={handleClose} size='lg' centered scrollable>
                    <Modal.Header>
                        <Modal.Title>
                            <PageHead pagehead={`PayOut${crtPayOut != null ? ' Update' : ' Creation'}`} onClick={handleClose} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container fluid>
                            <Table>
                                <tr>
                                    <td className='w-50'>
                                        <div>
                                            <DropDownUI
                                                optionlist={purchasepartyData.map(user => ({
                                                    value: user.party_id,
                                                    label: user.party_name
                                                }))}
                                                placeholder='Select Party'
                                                labelname='Select Party'
                                                name='party_id'
                                                value={crtPayOut != null ? crtPayOut.party_id : formData.party_id}
                                                onChange={(updatedFormData) => {
                                                    if (crtPayOut != null) {
                                                        setCrtPayOut({ ...crtPayOut, party_id: updatedFormData.party_id })
                                                    } else {
                                                        setFormData({ ...formData, party_id: updatedFormData.party_id })
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td className='float-end'>
                                        <div className='py-3'>

                                            <div>
                                                <Calender setLabel={(date) => setLabel(date, 'voucher_date')} selectedDate={crtPayOut != null ? crtPayOut.voucher_date : formData.voucher_date} calenderlabel="Date" />
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    </td>
                                    <td>
                                        <div className='py-3 w-75 ms-auto' >
                                            <TextInputForm
                                                labelname={'Paid'}
                                                placeholder={'Paid'}
                                                name='paid'
                                                value={crtPayOut != null ? crtPayOut.paid : formData.paid}
                                                onChange={(e) => handleChange(e, 'paid')}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </Table>
                            <Row>
                                <Col lg="6">

                                </Col>
                                <Col lg="6">
                                    <div>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Col lg='12' md='12' xs='12' className='py-5 align-self-center'>
                            <div className='text-center'>
                                {crtPayOut != null ? (
                                    <>
                                        <ToastContainer
                                            position="top-center"
                                            autoClose={3000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                            theme="light"
                                        />
                                        <span className='mx-2'>
                                            <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

                                        </span>

                                        <span className='mx-2'>
                                            <ClickButton label={<>Cancel</>} onClick={handleClose}></ClickButton>
                                        </span>
                                    </>

                                ) : (
                                    <>
                                        <ToastContainer
                                            position="top-center"
                                            autoClose={3000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                            theme="light"
                                        />
                                        <span className='mx-2'>
                                            <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>
                                        </span>

                                        <span className='mx-2'>
                                            <ClickButton label={<>Cancel</>} onClick={() => { handleClose() }}></ClickButton>
                                        </span>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Modal.Footer>
                </Modal>
            </>
        </div>
    )
}

export default PayOut