import React from 'react'
import { PDFViewer } from '@react-pdf/renderer';
import PayInPdf from './PayInPdf';
const PayInPreview = () =>  (
    <PDFViewer height={"1000"} width={"100%"}>
        <PayInPdf/>
    </PDFViewer>
)


export default PayInPreview