import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SideBar from './components/SideBar'
import Dashboard from './pages/Dashboard';
import ProductList from './pages/ProductList';
import CategoryList from './pages/CategoryList';
import UnitList from './pages/UnitList';
import ItemCreation from './pages/ItemCreation';
import Party from './pages/PurchaseParty';
import SalesParty from './pages/SalesParty'
import PurchaseBill from './pages/PurchaseBilllisting';
import PayOut from './pages/PayOut';
import PurchaseReturn from './pages/PurchaseReturn';
import './components/Table.css'
import { PurchaseBillEntry, PurchaseReturnEntry } from './pages/PurchaseBills';
import SalesInvoice from './pages/SalesInvoice';
import { EstimationEntry, SalesInvoiceEntry } from './pages/SalesBills';
import Estimation from './pages/Estimation';
import PayIn from './pages/PayIn';
import SalesReturn from './pages/SalesReturns';
import User from './pages/User';
import Company from './pages/Company';
import DayBook from './pages/DayBook';
import Reports from './pages/Reports';
import Login from './pages/Login';
import { PdfEstimatePreview, PdfPreview } from './pages/PdfPreview';
import DayBookPreview from './pages/DaybookPreview';
import PurchasePreviw from './pages/PurchasePreviw';
import SalePreview from './pages/SalePreview';
import PayInPreview from './pages/PayInPreview';
import PayoutPreview from './pages/PayoutPreview';
import PurchaseBillReceipt from './pages/PurchaseBillReceipt';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const session = localStorage.getItem('session');
    if (session) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const handleLogin = () => {
    localStorage.setItem('session', 'true');
    setLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('session');
    setLoggedIn(false);
    return <Navigate to="/login" replace />;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={loggedIn ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />} />
          <Route path='/login' element={<Login onLogin={handleLogin} />}></Route>
          <Route element={<SideBar onLogout={handleLogout} />}>
            <Route path='/dashboard' element={<Dashboard />} ></Route>
            <Route path='/console/user' element={<User />}></Route>
            <Route path='/console/company' element={<Company />}></Route>
            <Route path='/console/party/purchase' element={<Party />}></Route>
            <Route path='/console/party/sales' element={<SalesParty />}></Route>
            <Route path='/console/item/product' element={<ProductList />}></Route>
            <Route path='/console/item/category' element={<CategoryList />}></Route>
            <Route path='/console/item/unit' element={<UnitList />}></Route>
            <Route path='/console/item/create' element={<ItemCreation />}></Route>
            <Route path='/console/purchase/bill' element={<PurchaseBill />}></Route>
            <Route path='/console/purchase/payout' element={<PayOut />}></Route>
            <Route path='/console/purchase/return' element={<PurchaseReturn />}></Route>
            <Route path='/console/sales/invoice' element={<SalesInvoice />}></Route>
            <Route path='/console/sales/estimation/' element={<Estimation />}></Route>
            <Route path='/console/sales/payin' element={<PayIn />}></Route>
            <Route path='/console/sales/return/' element={<SalesReturn />}></Route>
            <Route path='/console/expense/' element={<PurchaseReturnEntry />}></Route>
            <Route path='/console/daybook' element={<DayBook />}></Route>
            <Route path='/console/report' element={<Reports />}></Route>
          </Route>
          <Route path='/console/purchase/bill/create' element={<PurchaseBillEntry />}></Route>
          <Route path='/console/purchase/return/create' element={<PurchaseReturnEntry />}></Route>
          <Route path='/console/sales/invoice/create' element={<SalesInvoiceEntry />}></Route>
          <Route path='/console/sales/estimation/create' element={<EstimationEntry />}></Route>
          <Route path='/console/expense/create' element={<PurchaseReturnEntry />}></Route>
          <Route path='/console/pdf' element={<PdfPreview />}></Route>
          <Route path='/console/PdfEstimation' element={<PdfEstimatePreview />}></Route>
          <Route path='/console/purchasepreview' element={<DayBookPreview />}></Route>
          <Route path='/console/purchaseprw' element={<PurchasePreviw />}></Route>
          <Route path='/console/salepreview' element={<SalePreview />}></Route>
          <Route path='/console/payinpreview' element={<PayInPreview />}></Route>
          <Route path='/console/payoutpreview' element={<PayoutPreview />}></Route>
          <Route path='/console/purchasebillreceipt' element={<PurchaseBillReceipt />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
