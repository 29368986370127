import React from 'react';
import { Page, Text, View, Document, StyleSheet, } from '@react-pdf/renderer';
import moment from 'moment'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    padding: 12,
  },
  section: {
    flexDirection: 'column',
    flexGrow: 1,
    border: 1,
    display: 'flex',
  },
  text: {
    fontSize: 13,
    fontFamily: 'Times-Roman',
    textAlign: 'center',
    borderBottom: 1,
    width: '100%',
  },
  // invoice-title start
  headertext: {
    fontSize: 10,
    fontFamily: 'Times-Roman',
    borderBottom: 1,
    width: '100%',
    padding: 6
  },
  head: {
    fontSize: 10,
    textAlign: "center"
  },
  date: {
    fontSize: 10,
    width: "50%",
    padding: 0,
    textAlign: "right"
  },
  title: {
    fontWeight: "bolder",
    fontSize: 15,
    textAlign: "center",
    width: "100%",
    marginBottom: 9
  },
  companyaddress: {
    fontWeight: "bolder",
    fontSize: 10,
    textAlign: "center",
    width: "100%",
    marginBottom: 0
  },
  companymobile: {
    fontWeight: "bolder",
    fontSize: 10,
    textAlign: "center",
    width: "100%",
    marginBottom: 7
  },
  companygst: {
    fontWeight: "bold",
    fontSize: 11,
    textAlign: "center",
    width: "100%",
    marginBottom: 7
  },
  name: {
    fontSize: 10,
    width: "50%",
    textAlign: "left"
  },
  // invoice-title start
  // table start
  table: {
    display: 'flex',
    padding: 6,
    justifyContent: 'flex-end',
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableCell: {
    flex: 4,
    fontSize: 9,
    fontFamily: 'Times-Roman',
  },
  // table end 
  // product start
  producthead: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    borderBottom: 1
  },
  producttitle: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    display: 'flex',
    flexDirection: 'row',
    width: "100%",
    borderBottom: 1
  },
  productbody: {
    fontSize: 8,
    fontFamily: 'Times-Roman',
    display: 'flex',
    flexDirection: 'row',
  },
  no: {
    width: "20%",
    borderRight: 1,
    padding: 5
  },
  dataDate: {
    width: "50%",
    borderRight: 1,
    padding: 5,
  },
  dataReceipt: {
    width: "50%",
    borderRight: 1,
    padding: 5,
  },
  partyname: {
    width: "90%",
    borderRight: 1,
    padding: 5,
  },
  dataSubTotal: {
    width: "40%",
    borderRight: 1,
    padding: 5,
  },
  dataTax: {
    width: "40%",
    borderRight: 1,
    padding: 5,
  },
  // datarow: {
  //   width: "70%",
  //   borderRight: 1,
  //   padding: 5,
  // },

  dataend: {
    width: "40%",
    padding: 5,
  },
  // product end 
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 9,
    fontFamily: 'Times-Roman',
    padding: 6,
    paddingBottom: 3
  },
  lastpage: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 7,
    marginBottom: 20
  },
  totaledit: {
    fontSize: 14,
    fontWeight: "bolder",
    marginLeft: 260
  },
  suptotal: {
    fontSize: 9,
    fontWeight: "bolder",
    marginLeft: 77
  },
  supgst: {
    fontSize: 9,
    fontWeight: "bolder",
    marginLeft: 30
  },
  supgrandtotal: {
    fontSize: 9,
    fontWeight: "bolder",
    marginLeft: 25
  },
  tablesrow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: 1,
  },

  TaxableValue: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: "bolder",
    textAlign: "center",
  },
  endtotal: {
    fontSize: 10,
    fontWeight: "bolder",
  },
  tablessrow: {
    borderBottom: 1,
    width: "25%",
    borderRight: 1
  },
  tablesssrow: {
    borderBottom: 1,
    fontSize: 14,
    textAlign: "center",
    marginBottom: 2
  },

  lastfles: {
    flexDirection: "row",
    display: 'flex',
    width: "100%",
  },
  lastfleskanna: {
    borderRight: 1,
    width: "40%",

  },
  lastfleskannan: {
    width: "60%",

  },
  taxnames: {
    fontSize: 14,
    textAlign: "center"
  },


  //kljkghkgfjhhgjnkl

  tablesrows: {
    display: 'flex',
    flexDirection: 'row',
  },
  tablessrows: {

    width: "25%",
    borderRight: 1
  },
  TaxableValues: {

    fontSize: 14,
    fontWeight: "bolder",
    textAlign: "center",
    borderBottom: 1
  },
  lastfless: {
    flexDirection: "row",
    display: 'flex',
    width: "100%",
    borderBottom: 1
  },
  lastfleskannas: {
    borderRight: 1,
    width: "40%",

  },
  lastfleskannans: {
    width: "60%",

  },
  lastflesss: {
    flexDirection: "row",
    display: 'flex',
    width: "100%",
    borderBottom: 1
  },

});

const ITEMS_PER_PAGE = 35; // Number of items per page

const SaleRep = ({ data, partyid, pn, fromdate, todate }) => {
  console.log('fromdatepdf', fromdate);
  const subTotal = data.reduce((acc, item) => acc + item.sum_total, 0);
  const subgrandtotal = data.reduce((acc, item) => acc + item.total, 0);
  const subgst = data.reduce((acc, item) => acc + (item.total - item.sum_total), 0)

  const subTotals = data.reduce((acc, item) => acc + item.sum_total / 2, 0);
  const subgsts = data.reduce((acc, item) => acc + (item.total - item.sum_total) / 2, 0)
  const chunkData = (data, size) => {
    const chunkedArray = [];
    for (let i = 0; i < data.length; i += size) {
      chunkedArray.push(data.slice(i, i + size));
    }
    return chunkedArray;
  };

  const pages = chunkData(data, ITEMS_PER_PAGE);
  const totalPages = pages.length;
  const formattedFromDate = moment(fromdate).format('DD-MM-YYYY');
  const formattedtoDate = moment(todate).format('DD-MM-YYYY');
  return (
    <Document>
      {pages.map((pageData, pageIndex) => (
        <Page size="A4" style={styles.page} key={pageIndex}>
          <View style={styles.section}>
            <View style={styles.header}>
              <View style={styles.headertext}>
                <View style={styles.head}>
                  <Text style={styles.title}>{data[0].company_details.company_name} </Text>
                  <Text style={styles.companyaddress}>{data[0].company_details.address} </Text>
                  <Text style={styles.companymobile}>{data[0].company_details.mobile_number} </Text>
                  <Text style={styles.companygst}>GSTIN : {data[0].company_details.gst_no} </Text>fromdate
                  {formattedFromDate !== "" ? (<Text style={styles.companygst}>Sales Details ({formattedFromDate} To {formattedtoDate}) </Text>) : ""}
                </View>
              </View>
            </View>
            <View style={styles.producthead}>
              <View style={styles.producttitle}>
                <Text style={styles.no}>S No</Text>
                <Text style={styles.dataDate}>Date</Text>
                <Text style={styles.dataReceipt}>Receipt No</Text>
                <Text style={styles.partyname}>Party Name</Text>
                <Text style={styles.dataSubTotal}>SubTotal</Text>
                <Text style={styles.dataTax}>TaxAmount</Text>
                <Text style={styles.dataend}>GrandTotal</Text>
              </View>
              <View style={styles.bodydescription}>
                {pageData.map((product, index) => {
                  const gst = (product.total - product.sum_total).toFixed(2); // Calculate GST
                  return (
                    <View style={styles.productbody} key={index}>
                      <Text style={styles.no}>{pageIndex * ITEMS_PER_PAGE + index + 1}</Text>
                      <Text style={styles.dataDate}>{moment(product.bill_date).format('DD-MM-YYYY')}</Text>
                      <Text style={styles.dataReceipt}>{product.bill_no}</Text>
                      <Text style={styles.partyname}>{product.party_details.party_name}</Text>
                      <Text style={styles.dataSubTotal}>{product.sum_total}</Text>
                      <Text style={styles.dataTax}>{gst}</Text> {/* Display GST */}
                      <Text style={styles.dataend}>{product.total}</Text>
                    </View>
                  );
                })}
              </View>
            </View>
            {pageIndex === totalPages - 1 && (
              <View>
                <View style={styles.lastpage}>
                  <View style={styles.lastone}>
                    <Text style={styles.totaledit}>Total</Text>
                  </View>
                  <View style={styles.lastone}>
                    <Text style={styles.suptotal}>{subTotal.toFixed(2)}</Text>
                  </View>
                  <View style={styles.lastone}>
                    <Text style={styles.supgst}> {subgst.toFixed(2)}</Text>
                  </View>
                  <View style={styles.lastone}>
                    <Text style={styles.supgrandtotal}> {subgrandtotal.toFixed(2)}</Text>
                  </View>
                </View>
                {/* <View style={styles.tablesrow}>
                  <View style={styles.tablessrow}>
                    <Text style={styles.TaxableValue}>TaxableValue</Text>
                  </View>
                  <View style={styles.tablessrow}>
                    <Text style={styles.tablesssrow}>CenterlTax</Text>
                    <View style={styles.lastfles}>
                      <View style={styles.lastfleskanna}>
                        <Text style={styles.taxnames}>Rate </Text>
                      </View>
                      <View style={styles.lastfleskannan}>
                        <Text style={styles.taxnames} >Amount </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.tablessrow}>
                    <Text style={styles.tablesssrow}>StateTax</Text>
                    <View style={styles.lastfles}>
                      <View style={styles.lastfleskanna}>
                        <Text style={styles.taxnames}>Rate </Text>
                      </View>
                      <View style={styles.lastfleskannan}>
                        <Text style={styles.taxnames} >Amount </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.tablessrow}>
                    <Text style={styles.TaxableValue}>TotalTaxAmount</Text>
                  </View>
                </View>
                <View style={styles.tablesrows}>
                  <View style={styles.tablessrows}>
                    <Text style={styles.TaxableValues}>{subTotals.toFixed(2)}</Text>
                    <Text style={styles.TaxableValues}>{subTotal.toFixed(2)}</Text>
                  </View>
                  <View style={styles.tablessrows}>
                    <View style={styles.lastfless}>
                      <View style={styles.lastfleskannas}>
                        <Text style={styles.taxnames}>2.5% </Text>
                      </View>
                      <View style={styles.lastfleskannans}>
                        <Text style={styles.taxnames} >{subgsts.toFixed(2)} </Text>
                      </View>
                    </View>
                    <View style={styles.lastflesss}>
                      <View style={styles.lastfleskannas}>
                        <Text style={styles.taxnames}> </Text>
                      </View>
                      <View style={styles.lastfleskannans}>
                        <Text style={styles.taxnames} >{subgsts.toFixed(2)} </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.tablessrows}>
                    <View style={styles.lastfless}>
                      <View style={styles.lastfleskannas}>
                        <Text style={styles.taxnames}>2.5%</Text>
                      </View>
                      <View style={styles.lastfleskannans}>
                        <Text style={styles.taxnames} >{subgsts.toFixed(2)} </Text>
                      </View>
                    </View>
                    <View style={styles.lastflesss}>
                      <View style={styles.lastfleskannas}>
                        <Text style={styles.taxnames}></Text>
                      </View>
                      <View style={styles.lastfleskannans}>
                        <Text style={styles.taxnames} >{subgsts.toFixed(2)} </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.tablessrows}>
                    <Text style={styles.TaxableValues}>{subgst.toFixed(2)}</Text>
                    <Text style={styles.TaxableValues}>{subgst.toFixed(2)}</Text>
                  </View>
                </View> */}
              </View>

            )}
          </View>
          <Text style={styles.footer}>
            Page {pageIndex + 1} of {totalPages}
          </Text>
        </Page>
      ))}
    </Document>
  );
};

export default SaleRep;
