import React from 'react'
 import PurchasePDF from './DayBookPDF'
import { PDFViewer } from '@react-pdf/renderer';
const DayBookPreview = () => 
  (
    <PDFViewer height={"1000"} width={"100%"}>
        <PurchasePDF />
    </PDFViewer>
  )


export default DayBookPreview