import React from 'react'
import { PDFViewer } from '@react-pdf/renderer';
import PayoutPdf from './PayoutPdf';
const PayoutPreview = () =>  (
    <PDFViewer height={"1000"} width={"100%"}>
        <PayoutPdf/>
    </PDFViewer>
)


export default PayoutPreview