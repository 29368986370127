import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Offcanvas, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Buttons, ClickButton, Purchase } from '../components/Buttons';
import { TableUI } from '../components/Tables'
import { useLocation, Navigate } from 'react-router-dom';
import { Calender, DropDownUI, TextInputForm } from '../components/Forms';
import { MdOutlineClose } from "react-icons/md";
import { PageHead, PageTitle } from '../components/PageTitle';
import 'react-toastify/dist/ReactToastify.css';
import { FaMagnifyingGlass } from "react-icons/fa6";
const ProductHistory = ["S.No", "Type", "Date", "Receipt.No", "Amount"]
const ProductTablehead = ["Name", "Action"]
const ProductList = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [openFilter, setFilter] = useState(false);
  const closeFilter = () => setFilter(false);
  const showFilter = () => setFilter(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    })
  })
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [clickedData, setClickedData] = useState(null);
  console.log('clickedData', clickedData)
  const handleClick = (data) => {
    setClickedData(data);
  };
  const [searchText, setSearchText] = useState('');
  const handleSearch = (value) => {
    setSearchText(value);
    //console.log(value);
  };
  const [userData, setUserData] = useState([]);
  console.log('userData', userData);
  const fetchData = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://billing.barbikan.in/product/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: searchText
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      setLoading(false);
      console.log(responseData)
      if (responseData.status === 200) {
        setUserData(responseData.data);
        console.log(setUserData)
        if (responseData.data.length > 0) {
          setClickedData(responseData.data[0]);
        }
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  useEffect(() => {


    fetchData(); // Call fetchData directly in useEffect

  }, [searchText]);

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem('session');
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking session:', error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {

    return <Navigate to="/login" replace />;
  }
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg="6" className='py-1'>
            <div className='main my-2'>
              <Row>
                <Col lg="7">
                  <div className=' mx-2'>
                    {/* <Buttons lable={<>Filter</>} onClick={showFilter} /> */}
                    <TextInputForm placeholder={"Name"}
                      onChange={(e) => handleSearch(e.target.value)}
                      prefix_icon={<FaMagnifyingGlass />}
                      labelname={"Search"}> </TextInputForm>
                  </div>
                </Col>
                <Col lg='5' className='d-flex align-item-baseline justify-content-end'>
                  <div className='text-end'><Buttons lable={<> Add New</>} onClick={() => navigate("/console/item/create")} /></div>
                </Col>
              </Row>
              <div className='py-2 c-table'>
                <TableUI headers={ProductTablehead} body={userData} type="itemsproduct" onClick={handleClick} onDelete={fetchData} ></TableUI>
              </div>
            </div>
          </Col>
          <Col lg='6'>
            <div className='my-2'>
              <div className='main'>
                {clickedData &&
                  <div className='d-flex justify-content-between'>
                    <div>
                      <div className='product-name py-2'>Product Name : {clickedData.product_name}</div>
                      <div className='sale-price py-2'> Opening Stock : <span>{clickedData.opening_stock}</span></div>
                      <div className='sale-price py-2'> current Stock : <span>{clickedData.crt_stock}</span></div>
                      <div className='sale-price py-2'> Opening Stock : <span>{clickedData.opening_date}</span></div>
                    </div>
                    <div>
                      {/* <div className='product-name py-2'><Buttons lable={<>Adjust Items</>} onClick={handleShow} /></div> */}
                    </div>
                  </div>
                }
              </div>
            </div>
            {/* <div className='my-2'>
                    <div className='main'>
                      <div className='d-flex justify-content-between'>
                        <div>
                          <div className='product-name py-2'>Chocobar</div>
                          <div className='sale-price py-2'> Sale Price : <span>45 (excl)</span></div>
                          <div className='sale-price py-2'> Purchase Price : <span>23 (excl)</span></div>
                          <div className='sale-price py-2'> wholesale Price : <span>20 (excl)</span></div>
                        </div>
                        <div className='text-end'>
                          <div className='product-name py-2'><Buttons lable={<>Adjust Items</>} /></div>
                          <div className='stock-qty py-2'> Stock Quantity : <span>2563</span></div>
                          <div className='stock-value py-2'> Stock Value : <span>12530 (excl)</span></div>
                        </div>
                      </div>
                    </div>
              </div> */}
            {/* <div className='my-3'>
              <div className='main'>
                <Row>
                  <Col lg='4'>
                    <PageTitle PageTitle={<>Transactions</>} />
                  </Col>
                  <Col lg="8">
                    <div className='text-end'>
                      <Buttons lable={<>Filter</>} onClick={showFilter} />
                    </div>
                  </Col>
                </Row>
                <Offcanvas show={openFilter} onHide={closeFilter} placement='end'>
                  <Offcanvas.Body>
                    <Row>
                      <Col lg="6">
                        <PageTitle PageTitle={<>Sale Party</>} />
                      </Col>
                      <Col lg="6" className='align-self-center'>
                        <div className='text-end'>
                          <Buttons onClick={closeFilter} lable={<><MdOutlineClose /></>}></Buttons>
                        </div>
                      </Col>
                    </Row>
                    <div className='py-3'>
                      <TextInputForm labelname={<>Invoice No.</>} />
                    </div>
                    <div className='py-3'>
                      <DropDownUI labelname={"Select Sale Party"} />
                    </div>
                    <div className='py-3'>
                      <DropDownUI labelname={" Select Type"} />
                    </div>
                    <Row>
                      <Col lg="6" className='align-self-center'>
                        <div>
                          <Calender labelname={<>From</>} />
                        </div>
                      </Col>
                      <Col lg="6" className='align-self-center'>
                        <div>
                          <Calender labelname={<>To</>} />
                        </div>
                      </Col>
                      <Col lg="6" className='align-self-center'>
                        <div className='my-5 text-center'>
                          <Buttons lable={"Apply"}></Buttons>
                        </div>
                      </Col>
                      <Col lg="6" className='align-self-center'>
                        <div className='my-5 text-center'>
                          <Buttons lable={"Clear"}></Buttons>
                        </div>
                      </Col>
                    </Row>
                  </Offcanvas.Body>
                </Offcanvas>
                <div className='py-2 c-table table-scroll'>
                  <TableUI headers={ProductHistory} body={clickedData != null ? clickedData.transactions : []} pageview={"yes"} type='itemsproducttransactions'></TableUI>
                </div>
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} centered size='xl'>
        <Modal.Header>
          <PageHead pagehead={<> Adjust Items</>} onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col lg="4" className='align-self-center'>
                <TextInputForm labelname={<>Purchase Stock</>} />
              </Col>
              <Col lg="4" className='align-self-center'>
                <TextInputForm labelname={<>Bill Stock</>} />
              </Col>
              <Col lg="4" className='align-self-center'>
                <TextInputForm labelname={<>Balance Stock</>} />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <span className='mx-2'>
            <ClickButton label={<>Submit</>} onClick={<></>}></ClickButton>
          </span>
          <span className='mx-2'>
            <ClickButton label={<>Cancel</>} onClick={() => handleClose()}></ClickButton>
          </span>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ProductList