import React,{useEffect,useState} from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { Calender, DropDown, TextInputForm,DropDownUI } from '../components/Forms';
import { Buttons } from '../components/Buttons';
const DropList = [
  {
    value: 'Purchase',
    label: 'Purchase'
  },
  {
    value: 'Sales',
    label: 'Sales'
  },
  {
    value: 'Expenses',
    label: 'Expenses'
  },
  {
    value: 'Payin',
    label: 'Payin'
  },
  {
    value: 'Payout',
    label: 'Payout'
  },
  {
    value: 'Estimation',
    label: 'Estimation'
  }
]
const DayBook = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [salesdata, setSalesData] = useState([]);
  const [expitemData, setexpitemData] = useState([]);
  const [payindata, setpayinData] = useState([]);
  const [payoutdata, setpayoutData] = useState([]);
  const [estimationData, setEstiData] = useState([]);
  const [partyNameFilter, setPartyNameFilter] = useState('');
  const [receiptTypeFilter, setReceiptTypeFilter] = useState([]);
  console.log(receiptTypeFilter);
  console.log('userData',userData);
  console.log('salesdata',salesdata);
  console.log('expitemData',expitemData);
  console.log('payindata',payindata);
  console.log('payoutdata',payoutdata);
  console.log('estimationData',estimationData);
  const fetchData = async () => {
    try {
        const companyId = localStorage.getItem('companyId');
        const response = await fetch(' https://billing.barbikan.in/purchasebill/list/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                search_text: ''
            })
        });

        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }

        const responseData = await response.json();
        console.log(responseData);
        setLoading(false);
        if (responseData.status === 200) {
            setUserData(responseData.data);
            console.log(setUserData)

        } else {
            throw new Error(responseData.msg);
        }
    } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error.message);
    }
};
const fetchsalesData = async () => {
  try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://billing.barbikan.in/saleinvoice/list/' + companyId, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              search_text: ''
          })
      });

      if (!response.ok) {
          throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
          setSalesData(responseData.data);


      } else {
          throw new Error(responseData.msg);
      }
  } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
  }
};
const fetchExpensesItemData = async () => {
  try {
    const companyId = localStorage.getItem('companyId');
    const response = await fetch('https://billing.barbikan.in/expenses/item/list/' + companyId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        search_text: ''
      })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const responseData = await response.json();
    console.log(responseData)
    setLoading(false);
    if (responseData.status === 200) {
      // Set userData to the array of purchase parties
      setexpitemData(responseData.data);
    } else {
      throw new Error(responseData.msg);
    }
  } catch (error) {
    setLoading(false);
  }
};
const fetchDatapayin = async () => {
  try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://billing.barbikan.in/payin/list/' + companyId, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              search_text: ''
          })
      });

      if (!response.ok) {
          throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
          setpayinData(responseData.data);
          console.log(responseData)

      } else {
          throw new Error(responseData.msg);
      }
  } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
  }
};
const fetchDatapayout = async () => {
  try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://billing.barbikan.in/payout/list/' + companyId, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              search_text: ''
          })
      });

      if (!response.ok) {
          throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
          // Set userData to the array of purchase parties
          setpayoutData(responseData.data);
          console.log(responseData)
      } else {
          throw new Error(responseData.msg);
      }
  } catch (error) {
      setLoading(false);

  }
};
const fetchDataestimation = async () => {
  try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://billing.barbikan.in/saleestimation/list/' + companyId, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              search_text: ''
          })
      });

      if (!response.ok) {
          throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
          setEstiData(responseData.data);

      } else {
          throw new Error(responseData.msg);
      }
  } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
  }
};
useEffect(() => {
  
  fetchData();
  fetchsalesData();
  fetchExpensesItemData();
  fetchDatapayin();
  fetchDatapayout();
  fetchDataestimation();
 
}, [])
const filteredUserData = userData.filter(data => {
  return data.party_details.party_name.toLowerCase().includes(partyNameFilter.toLowerCase());
});

const filteredSalesData = salesdata.filter(data => {
  return data.party_details.party_name.toLowerCase().includes(partyNameFilter.toLowerCase());
});

const filteredExpensesItemData = expitemData.filter(data => {
  return data.party_name.toLowerCase().includes(partyNameFilter.toLowerCase());
});

const filteredPayinData = payindata.filter(data => {
  return data.party_details.party_name.toLowerCase().includes(partyNameFilter.toLowerCase());
});

const filteredPayoutData = payoutdata.filter(data => {
  return data.party_details.party_name.toLowerCase().includes(partyNameFilter.toLowerCase());
});

const filteredEstimationData = estimationData.filter(data => {
  return data.party_details.party_name.toLowerCase().includes(partyNameFilter.toLowerCase());
});


  return (
    <div>
      <Container fluid>
        <div className='main my-3'>
              <Row>
                <Col lg='6' className='py-3'>
                  <div>DayBook </div>
                </Col>
                <Col lg='6' className='py-3'>
                  <div className='text-end'>
                        
                    <Buttons lable={<>PDF</>}/> 
                    </div>
                </Col>
                <Col lg='3' className='py-3'>
                <div><TextInputForm labelname={"Party Name"} value={partyNameFilter} onChange={e => setPartyNameFilter(e.target.value)} /></div>
                </Col>
              {/* <Col lg='3' className='py-3'>
              
                <div><DropDownUI
                  optionlist={DropList}
                  placeholder='Receipt Type'
                  labelname='Receipt Type'
                  name='receipttype'
                  value={receiptTypeFilter}
                  onChange={(updatedFormData) => setReceiptTypeFilter({ ...receiptTypeFilter, receipttype: updatedFormData.receipttype })}
                /></div>
              </Col>
              <Col lg='3' className='py-3'>
                  <div><Calender labelname={"From"}/></div>
              </Col>  
              <Col lg='3' className='py-3'>
                    <div><Calender labelname={"To"}/></div>
              </Col> */}
                <Col lg='12' className='py-3'>
                  <div className='wire-table my-3'>
                  <Table>
                    <thead className=''>
                      <tr>
                        <th className=''>S No</th>
                        <th>Date</th>
                        <th className='wrdbrk'>Receipt No.</th>
                        <th  className='wrdbrk '>Receipt Type</th>
                        <th className='wrdbrk'>Party Name</th>
                        <th className='wrdbrk'>Debit  (Rs.)</th>
                        <th className='wrdbrk'>Credit (Rs.)</th>
                      </tr>
                    </thead>
                    <tbody>
                    {filteredUserData.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.bill_date}</td>
                        <td>{data.bill_no}</td>
                        <td>Purchase</td>
                        <td>{data.party_details.party_name}</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    ))}
                    {filteredSalesData.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.bill_date}</td>
                        <td>{data.bill_no}</td>
                        <td>Sales</td>
                        <td>{data.party_details.party_name}</td>
                        <td>-</td>
                        <td>{data.total}</td>
                      </tr>
                    ))}
                    {filteredExpensesItemData.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.bill_date}</td>
                        <td>{data.bill_no}</td>
                        <td>Expenses</td>
                        <td>{data.party_name}</td>
                        <td>{data.total}</td>
                        <td>-</td>
                      </tr>
                    ))}
                    {filteredPayinData.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.receipt_date}</td>
                        <td>{data.receipt_no  }</td>
                        <td>Payin</td>
                        <td>{data.party_details.party_name}</td>
                        <td>-</td>
                        <td>{data.paid}</td>
                      </tr>
                    ))}
                    {filteredPayoutData.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.voucher_date}</td>
                        <td>{data.voucher_no  }</td>
                        <td>Payout</td>
                        <td>{data.party_details.party_name}</td>
                        <td>{data.paid}</td>
                        <td>-</td>
                      </tr>
                    ))}
                    {filteredEstimationData.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.estimate_date}</td>
                        <td>{data.estimate_no  }</td>
                        <td>Estimation</td>
                        <td>{data.party_details.party_name}</td>
                        <td>-</td>
                        <td>-</td>
                       
                      </tr>
                    ))}
                     
                      {/* <tr>
                        <td className='text-end' colSpan={'5'}>Total </td>
                        <td>5686</td>
                        <td>8963</td>
                      </tr> */}
                    </tbody>
                  </Table>
                  </div>
                </Col>
                </Row>
        </div>
      </Container>
    </div>
  )
}

export default DayBook