import React, { useState } from "react";
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Forms.css'

const InstantModel = ({ optionlist = [], className, name, labelname, placeholder, value, onChange, modeltitle = "create" }) => {
  const handleChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    if (selectedValue !== value) {
      onChange({
        ...value,
        [name]: selectedValue
      });
    }
  };

  // Find the selected option based on the current value
  const selectedOption = optionlist.find(option => option.value === value);

  return (
    <>
      <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>

      <div className='w-100 d-flex'>
        <Select
          placeholder={placeholder}
          options={optionlist}
          labelField='label'
          valueField='value'
          value={selectedOption} // Set the selected option
          onChange={handleChange}
          multi
          className='w-100'
        />


      </div>
    </>
  );
};


const TextInputForm = ({ name, type, suffix_icon, prefix_icon, labelname, value, onChange, placeholder, onKeyDown, autoFocus, disabled, onKeyPress }) => {
  return (
    <>
      <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <div className='form-icon'>
        <Form.Group className="">
          {prefix_icon ? <span className='prefix-icon'>{prefix_icon}</span> : ""}
          <input
            type={type}
            name={name}
            className={`form-cntrl w-100 
                      ${prefix_icon && suffix_icon ? "form-control-padboth" : prefix_icon ? "form-control-padleft" : suffix_icon ? "form-control-padright" : ""}`}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
            disabled={disabled}
            onKeyPress={onKeyPress}
          />
          {suffix_icon ? <span className='suffix-icon'>{suffix_icon}</span> : ""}
        </Form.Group>
      </div>
    </>
  )
}
const DropDown = ({ optionlist, labelname, placeholder, modeltitle = "Create" }) => {
  return (
    <>
      <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <div className='w-100 d-flex'>
        <Select
          options={optionlist}
          placeholder={placeholder}
          labelField='label'
          valueField='value'
          isMulti className='w-100 '
        >
        </Select>
      </div>
    </>
  )
}

const DropDownUI = ({ optionlist = [], className, name, labelname, placeholder, value, onChange, onKeyDown, autoFocus, disabled }) => {
  const handleChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    if (selectedValue !== value) {
      onChange({
        ...value,
        [name]: selectedValue
      });
    }
  };

  // Find the selected option based on the current value
  const selectedOption = optionlist.find(option => option.value === value);
  return (
    <>
      <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>

      <div className='w-100 d-flex'>
        <Select
          placeholder={placeholder}
          options={optionlist}
          labelField='label'
          valueField='value'
          value={selectedOption} // Set the selected option
          onChange={handleChange}
          multi
          className='w-100'
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      </div>
    </>
  );
};
const Calender = ({ setLabel, selectedDate, calenderlabel }) => {
  const [startDate, setStartDate] = useState(selectedDate != null ? selectedDate : new Date());

  return (
    <>
      <div className='pb-2 px-3'>
        <label>{calenderlabel}</label>
      </div>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          setLabel(date, 'date_of_birth'); // Pass 'date_of_birth' as the field name
        }}
        dateFormat='dd-MM-yyyy'
        className="w-100 form-cntrl"
        selectsStart
        startDate={startDate}
      />
    </>
  );
};
export { TextInputForm, DropDown, DropDownUI, Calender, InstantModel }