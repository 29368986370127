import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 12,
  },
  section: {
    flexDirection: 'column',
    flexGrow: 1,
    border: 1,
    display: 'flex',
  },
  text: {
    fontSize: 13,
    fontFamily: 'Times-Roman',
    textAlign: 'center',
    borderBottom: 1,
    width: '100%',
  },
  // invoice-title start
  headertext: {
    fontSize: 10,
    fontFamily: 'Times-Roman',
    borderBottom: 1,
    width: '100%',
    padding: 6
  },
  head: {
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
    textAlign: "center",
  },

  invoice: {
    flex: 800,
    fontWeight: "bolder",
    fontSize: 13
  },
  // invoice-title start
  // table start
  table: {
    display: 'flex',
    borderBottom: 0.5,
    padding: 6,

    justifyContent: 'flex-end',

  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableCell: {
    flex: 4,
    fontSize: 9,
    fontFamily: 'Times-Roman',
  },
  // table end 
  // product start
  producthead: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    borderBottom: 1,
  },
  producttitle: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    display: 'flex',
    flexDirection: 'row',
    width: "100%",
    padding: 5,
    borderBottom: 1,
  },
  productbody: {
    fontSize: 8,
    fontFamily: 'Times-Roman',
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
  },
  bodydescription: {
    height: 250
  },
  no: {
    width: "50%",
    flexGrow: 10, display: 'flex',
  },
  particular: {
    width: "200%",
    display: 'flex',
  },
  qty: {
    width: "100%",
    flexGrow: 10, display: 'flex',
  },
  rate: {
    width: "100%",

  },
  gst: {
    width: "100%",
    display: 'flex',
    flexGrow: 20,
    flexDirection: 'column',
  },
  amount: {
    width: "100%",
    flexGrow: 10,
    display: 'flex',
  },
  // product end
  // footer start
  footer: {
    fontSize: 9,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footerRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footerleft: {
    height: 62, borderBottom: 0.5,
  },
  footerCell: {
    flex: 4,
    fontSize: 9,
    fontFamily: 'Times-Roman', paddingVertical: 5
  },
  // footer end
  // price word start
  priceRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderBottom: 0.5,
    flexGrow: 15,
  },
  priceCell: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    paddingVertical: 5,
    flexGrow: 10,
    borderRight: 0.5
  },
  overall: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    paddingVertical: 5,
    padding: 5,
    flexGrow: 10,
  },
  // price word end
  // signs start
  signbox: {
    fontSize: 9,
    display: 'flex',
  },
  signdesk: {
    height: 59,

  },
  signbill: {
    flexDirection: 'row',
  },
  customsign: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    paddingVertical: 5,
    flexGrow: 200,
    marginTop: 39

  },
  docs: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    paddingVertical: 5,
    padding: 5,
    marginTop: 39
  },
  declration: {
    fontSize: 8
    , marginTop: 33,
    padding: 4
  },
  boxdetails: {
    display: "flex",
    flexDirection: 'row',
    borderBottom: 1
  },
  box: {
    fontSize: 9,
    width: "50%",
    padding: 5
  },
  brdright: { borderRight: 1 },
  print: {
    fontSize: 9,
    textAlign: "right"
  },
  w70: {
    width: "70%"
  },
  w30: {
    width: "30%"
  }
  // signs end
});
const PurchaseReceipt = ({ data }) => {
  const IGST = data.product.reduce((acc, product) => acc + parseFloat(product.IGST), 0);
  const SGST = data.product.reduce((acc, product) => acc + parseFloat(product.SGST), 0);
  const CGST = data.product.reduce((acc, product) => acc + parseFloat(product.CGST), 0);

  function convertRupeesToWords(amount) {
    const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const thousands = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];

    function convertGroup(number, thousandIndex) {
      const str = '000' + number;
      const hundred = ones[str[str.length - 3]];
      const ten = tens[str[str.length - 2]];
      const one = ones[str[str.length - 1]];

      const converted = [
        hundred === '' ? '' : hundred + ' Hundred',
        ten === '' ? '' : ten,
        one === '' ? '' : one
      ].filter(word => word !== '').join(' ');

      return converted === '' ? '' : converted + ' ' + thousands[thousandIndex];
    }

    if (amount === 0) {
      return 'Zero Rupees';
    }

    const groups = [];
    while (amount > 0) {
      groups.push(amount % 1000);
      amount = Math.floor(amount / 1000);
    }

    const words = groups.map((group, i) => convertGroup(group, i)).reverse().join(' ').trim();
    return words + ' Rupees';
  }
  const [userdata, setUserData] = useState([]);
  console.log('siva123', userdata);
  const filteredUserData = userdata.filter(item => item.company_id === data.party_details.company_id);
  console.log('Filtered userdata:', filteredUserData);
  const fetchData = async () => {
    try {
      const response = await fetch('https://billing.barbikan.in/company/list/joyg67j8t689t', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      //setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data);
        console.log(responseData)
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      //setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, [])
  const words = convertRupeesToWords(data.total);
  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={styles.headertext}>
              <View style={styles.head}>
                <Text style={styles.invoice}>PurchaseBill</Text>
                <Text style={styles.print}> </Text>
              </View>
            </View>
          </View>
          {/* Table for address */}
          <View style={styles.boxdetails}>
            <View style={[styles.box, styles.brdright]}>
              <Text>Invoice No: {data.bill_no}</Text>
              {/* <Text>E-way Bill No:</Text> */}
            </View>
            <View style={styles.box}>
              <Text>Date: {data.bill_date}</Text>
              {/* <Text>Vehicle No:</Text> */}
            </View>
          </View>
          <View style={[styles.boxdetails]}>
            {/* <View style={[styles.box, styles.brdright]}>
              {filteredUserData.length > 0 && <Text>{filteredUserData[0].company_name}</Text>}
              {filteredUserData.length > 0 && <Text >{filteredUserData[0].address}</Text>}
              {filteredUserData.length > 0 && <Text >{filteredUserData[0].mobile_number}</Text>}
            </View> */}
            <View style={[styles.box, styles.brdright]}>
              <Text >{data.company_details.company_name}</Text>
              <Text>{data.company_details.address}</Text>
              <Text>{data.company_details.gst_no}</Text>
              <Text>{data.company_details.mobile_number}</Text>
              <Text>{data.company_details.fssai_code}</Text>
            </View>
            <View style={styles.box}>
              <Text >{data.party_details.company_name}</Text>
              <Text>{data.party_details.address}</Text>
              <Text>{data.party_details.mobile_number}</Text>
            </View>
          </View>

          <View style={styles.producthead}>
            <View style={styles.producttitle}>
              <Text style={styles.no}>S No</Text>
              <Text style={styles.particular}>Particular</Text>
              <Text style={styles.qty}>Qty</Text>
              <Text style={styles.rate}>Rate</Text>
              <Text style={styles.gst}>GST</Text>
              <Text style={styles.amount}>Amount</Text>
            </View>
            <View style={styles.bodydescription}>
              {data.product.map((product, index) => (

                <View style={styles.productbody} key={index}>
                  <Text style={styles.no}>{index + 1}</Text>
                  <View style={styles.particular}>
                    <Text>{product.product_name}</Text>
                    <Text>{product.product_details}</Text>
                  </View>
                  <Text style={styles.qty}>{product.qty} </Text>
                  <Text style={styles.rate}>{product.price_unit}</Text>
                  <Text style={styles.gst}>{parseFloat(product.IGST) > 0 ? 'IGST@' + product.tax_percentage : 'GST@' + product.tax_percentage}%</Text>
                  <Text style={styles.amount}>{product.without_tax_amount}</Text>
                </View>
              ))};
            </View>
          </View>
          <View style={[styles.boxdetails]}>
            <View style={[styles.box, styles.brdright, styles.w70]}>
              <Text>Bank Name : {data.company_details.bank_name}</Text>
              <Text>Account No: {data.company_details.acc_no}</Text>
              <Text>IFSC  : {data.company_details.ifsc_code}</Text>
            </View>
            {parseFloat(IGST) > 0 ? (
              <View style={[styles.box, styles.w30]}>
                <Text> Total : {data.sum_total}</Text>
                <Text>IGST:   {IGST.toFixed(2)}</Text>
              </View>
            ) : (
              <View style={[styles.box, styles.w30]}>
                <Text> Total : {data.sum_total}</Text>
                <Text> CGST :  {CGST.toFixed(2)}</Text>
                <Text> SGST :   {SGST.toFixed(2)}</Text>
              </View>)}
          </View>
          <View style={styles.footer}>
            <View style={styles.priceleft}>
              <View style={styles.priceRow}>
                <Text style={styles.priceCell}> {words}</Text>
                <Text style={styles.overall}>Overall Total :{data.total} </Text>
              </View>
            </View>
          </View>
          <View style={styles.signbox}>
            <View style={styles.signdesk}>
              <View style={styles.signbill}>
                <View style={styles.customsign}>
                  <Text> Customer Sign</Text>
                </View>
                <Text style={styles.docs}>For {data.company_details.company_name} </Text>
              </View>
              <View>
                <Text style={styles.declration}> We Declare that this  invoice  shows the actual  price  of the goods described and that all particulars are  true and correct</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
export default PurchaseReceipt;

