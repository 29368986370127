import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Modal, Offcanvas } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useNavigate, Navigate } from 'react-router-dom';
import { Buttons, ClickButton } from '../components/Buttons';
import { TableUI } from '../components/Tables'
import { PageHead } from '../components/PageTitle';
import { Calender, DropDownUI, TextInputForm } from '../components/Forms';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { MdOutlineClose } from "react-icons/md";
import { PageTitle } from '../components/PageTitle';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductTablehead = ["Category ", 'Product', "Status"]
const ProductHistory = ["S.No", "Name", "Stock"]
const CategoryList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false)
    location.state = [];
  };
  const handleShow = () => {
    setFormData({ category_name: '' })
    setCategory(null)
    setShow(true);
  }
  const [openFilter, setFilter] = useState(false);
  const closeFilter = () => setFilter(false);
  const showFilter = () => setFilter(true);
  const [clickedData, setClickedData] = useState(null);
  const handleClick = (data) => {
    setClickedData(data);
  };
  const [crtCategory, setCategory] = useState(null);

  // Function to open modal
  const handleEditClick = (rowdata) => {
    setCategory(rowdata)
    setShow(true)

  };
  const [formData, setFormData] = useState({
    category_name: '',
  });
  const [userData, setUserData] = useState([]);
  console.log(userData);
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    if (crtCategory != null) {
      setCategory({
        ...crtCategory,
        [fieldName]: value
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: value
      });
    }
  };
  const [searchText, setSearchText] = useState('');
  const handleSearch = (value) => {
    setSearchText(value);
    //console.log(value);
  };
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://billing.barbikan.in/category/list/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: searchText
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data);
        console.log(setUserData)
        if (responseData.data.length > 0) {
          setClickedData(responseData.data[0])
        }
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, [searchText]);
  const [showAlert, setShowAlert] = useState(false);
  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",

    });
  }
  const handleSubmit = async () => {
    try {
      if (formData.name === '' || formData.password === '' || formData.phone_number === '') {
        if (formData.name === '') {
          errorAlert("Name is Must");
        } else if (formData.phone_number === '') {
          errorAlert("Mobile Number is Must");
        } else if (formData.password === '') {
          errorAlert("Password is Must")
        }

      } else {
        const companyId = localStorage.getItem('companyId');
        const response = await fetch('https://billing.barbikan.in/category/create/' + companyId, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
        console.log(formData);
        const responseData = await response.json();

        console.log(responseData);

        if (responseData.status === 200) {
          fetchData();
          successAlert(responseData.msg)
          setTimeout(() => {
            handleClose(); // Close modal after 10 seconds
          }, 2000);
        }
        else if (responseData.status === 400) {
          toast.error('Missing required fields!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else {
          setShowAlert(true);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await fetch('https://billing.barbikan.in/category/update/' + companyId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ // Include the company ID in the request
          category_id: crtCategory.category_id,
          category_name: crtCategory.category_name
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update company');
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.status === 200) {
        fetchData();
        toast.success('Category updated successfully!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          handleClose();
        }, 2000);

        // Navigate to the user list page after a delay

      } else {
        console.error(responseData.msg || 'Unknown error occurred during update');
      }
    } catch (error) {
      console.error('Error updating product:', error.msg);
    }

    setLoading(false);
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem('session');
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking session:', error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {

    return <Navigate to="/login" replace />;
  }
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg="4" className='py-3 '>
            <div className='main my-3'>
              <Row>
                <Col lg="7">
                  <div className=' mx-2'>
                    {/* <Buttons lable={<>Filter</>} onClick={showFilter} /> */}
                    <TextInputForm placeholder={"Name"}
                      onChange={(e) => handleSearch(e.target.value)}
                      prefix_icon={<FaMagnifyingGlass />}
                      labelname={"Search"}> </TextInputForm>
                  </div>
                </Col>
                <Col lg='5' className='d-flex align-item-baseline justify-content-end'>
                  <div className='text-end'><Buttons lable={<> Add New</>} onClick={handleShow} /></div>
                </Col>
              </Row>
              <div className='py-2 c-table'><TableUI headers={ProductTablehead} body={userData} type="itemscategory" onDelete={fetchData} handleEditClick={handleEditClick} onClick={handleClick}></TableUI></div>
            </div>
          </Col>
          <Col lg='8' className='ps-0'>
            <div className='my-3'>
              <div className='main'>
                {clickedData &&
                  <div className='d-flex justify-content-between'>
                    <div>
                      <div>CategoryName : {clickedData.category_name}</div>
                    </div>
                    <div>
                      <div><span>ProductCount : {clickedData.product_count}</span></div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className='my-2'>
              <div className='main'>
                <Row>
                  <Col lg="12">
                    <PageTitle PageTitle={<>Product</>} />
                  </Col>

                </Row>
                <Offcanvas show={openFilter} onHide={closeFilter} placement='end'>
                  <Offcanvas.Body>
                    <Row>
                      <Col lg="6">
                        <PageTitle PageTitle={<>Category</>} />
                      </Col>
                      <Col lg="6" className='align-self-center'>
                        <div className='text-end'>
                          <Buttons onClick={closeFilter} lable={<><MdOutlineClose /></>}></Buttons>
                        </div>
                      </Col>
                    </Row>
                    <div className='py-3'>
                      <DropDownUI labelname={"Select Category"} />
                    </div>
                    <div className='py-3'>
                      <DropDownUI labelname={" Select Product"} />
                    </div>
                    <Row>
                      <Col lg="6" className='align-self-center'>
                        <div>
                          <Calender labelname={<>From</>} />
                        </div>
                      </Col>
                      <Col lg="6" className='align-self-center'>
                        <div>
                          <Calender labelname={<>To</>} />
                        </div>
                      </Col>
                      <Col lg="6" className='align-self-center'>
                        <div className='my-5 text-center'>
                          <Buttons lable={"Apply"}></Buttons>
                        </div>
                      </Col>
                      <Col lg="6" className='align-self-center'>
                        <div className='my-5 text-center'>
                          <Buttons lable={"Clear"}></Buttons>
                        </div>
                      </Col>
                    </Row>
                  </Offcanvas.Body>
                </Offcanvas>
                <div className='py-2 c-table table-scroll'>
                  <TableUI headers={ProductHistory} body={clickedData != null ? clickedData.transactions : []} pageview={"yes"} type='itemscategorytransactions'></TableUI>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header>
            <PageHead pagehead={<> Add Category</>} onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <TextInputForm
              labelname={'Category Name'}
              placeholder={'Category Name'}
              name='category_name'
              value={crtCategory != null ? crtCategory.category_name : formData.category_name}
              onChange={(e) => handleChange(e, 'category_name')}
            ></TextInputForm>
            <div className='text-center py-4'>
              {crtCategory != null ? (
                <>
                  <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <span className='mx-2'>
                    <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

                  </span>

                  <span className='mx-2'>
                    <ClickButton label={<>Cancel</>} onClick={handleClose}></ClickButton>
                  </span>
                </>

              ) : (
                <>
                  <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <span className='mx-2'>
                    <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>
                  </span>

                  <span className='mx-2'>
                    <ClickButton label={<>Cancel</>} onClick={handleClose}></ClickButton>
                  </span>
                </>
              )}
            </div>

          </Modal.Body>
        </Modal>
      </>
    </div>
  )
}

export default CategoryList