import React from 'react'
import { PDFViewer } from '@react-pdf/renderer';
import PurchaseReport from './PurchaseReport';
const PurchasePreviw = () => 
    (
        <PDFViewer height={"1000"} width={"100%"}>
            <PurchaseReport/>
        </PDFViewer>
      )

export default PurchasePreviw