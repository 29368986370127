import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment'
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    padding: 26,
    position: 'relative'
  },
  section: {
    flexDirection: 'column',
    flexGrow: 1,
    border: 1,
    display: 'flex',
  },
  text: {
    fontSize: 13,
    fontFamily: 'Times-Roman',
    textAlign: 'center',
    borderBottom: 1,
    width: '100%',
  },
  // invoice-title start
  headertext: {
    fontSize: 10,
    fontFamily: 'Times-Roman',
    borderBottom: 1,
    width: '100%',
    padding: 2
  },
  head: {
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
    textAlign: "center",
  },

  invoice: {
    flex: 200,
    fontWeight: "bolder",
    fontSize: 13
  },
  // invoice-title start
  // table start
  table: {
    display: 'flex',
    borderBottom: 0.5,
    padding: 6,
    justifyContent: 'flex-end',

  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableCell: {
    flex: 4,
    fontSize: 9,
    fontFamily: 'Times-Roman',
  },

  // table end 
  // product start
  producthead: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    borderBottom: 1,
  },
  producttitle: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    display: 'flex',
    flexDirection: 'row',
    width: "100%",
    padding: 5,
    borderBottom: 1,
  },
  productbody: {
    fontSize: 8,
    fontFamily: 'Times-Roman',
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
  },
  bodydescription: {
    height: 178
  },
  no: {
    width: "50%",
    flexGrow: 10, display: 'flex',
  },
  particular: {
    width: "233%",
    display: 'flex',
  },
  hsn: {
    width: "100%",
    flexGrow: 10, display: 'flex',
  },
  qty: {
    width: "100%",
    flexGrow: 10, display: 'flex',

  },
  rate: {
    width: "100%",

  },
  gst: {
    width: "100%",
    display: 'flex',
    flexGrow: 20,
    flexDirection: 'column',
  },
  amount: {
    width: "100%",
    flexGrow: 10,
    display: 'flex',
    paddingLeft: 10
  },
  // product end
  // footer start
  footer: {
    fontSize: 9,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footerRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footerleft: {
    height: 62, borderBottom: 0.5,
  },
  footerCell: {

    fontSize: 9,
    fontFamily: 'Times-Roman', paddingVertical: 5,
    width: "100%",
  },
  right: {
    textAlign: "right"
  },
  totalBox: {
    position: 'absolute',
  },
  w70: {
    width: "70%"
  },
  w30: {
    width: "30%"
  },

  // footer end
  // price word start
  priceRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderBottom: 1,
    flexGrow: 15,

  },
  priceCell: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    paddingVertical: 5,
    flexGrow: 10,
    borderRight: 1,
    padding: 4
  },
  overall: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    paddingVertical: 5,
    padding: 5,
    flexGrow: 10,
    marginTop: 6
  },
  // price word end
  // signs start





  signbox: {
    fontSize: 9,
    display: 'flex',
  },
  signdesk: {
    height: 45,

  },
  signbill: {
    flexDirection: 'row',
  },
  customsign: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    paddingVertical: 5,
    flexGrow: 200,
    marginTop: 12

  },
  docs: {
    fontSize: 9,
    fontFamily: 'Times-Roman',
    paddingVertical: 5,
    padding: 5,
    marginTop: 12
  },
  declration: {
    fontSize: 8,
    padding: 1
  },

  boxdetails: {
    display: "flex",
    flexDirection: 'row',
    borderBottom: 1
  },
  boxdetailss: {
    display: "flex",
    flexDirection: 'row',

  },
  box: {
    fontSize: 9,
    width: "50%",
    padding: 5
  },
  brdright: { borderRight: 1 },
  brdrights: { borderRight: 1, borderBottom: 1 },
  print: {
    fontSize: 9,
    textAlign: "right"
  },
  companyname: {
    fontWeight: "bold",
    fontSize: 9
  },
  billed: {
    fontSize: 10,
    fontWeight: "bold"
  }, ewayno: {
    marginBottom: 15,
    fontSize: 11,
    fontWeight: 'bold'
  }, stateof: {
    fontSize: 11,
    fontWeight: 'bold'
  }, remarks: {
    fontSize: 6
  }
  // signs end
});
const Pdf = ({ data, type }) => {
  const IGST = data.product.reduce((acc, product) => acc + parseFloat(product.IGST), 0);
  const SGST = data.product.reduce((acc, product) => acc + parseFloat(product.SGST), 0);
  const CGST = data.product.reduce((acc, product) => acc + parseFloat(product.CGST), 0);

  // function convertRupeesToWordsWithBreaks(amount) {
  //   const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
  //   const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
  //   const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
  //   const thousands = ['', 'Thousand', 'Lakh', 'Crore']; // Adjusted for Indian system

  //   // Convert a three-digit group into words
  //   function convertGroup(number, thousandIndex) {
  //     if (number === 0) return '';

  //     let str = '000' + number;
  //     str = str.slice(-3);

  //     const hundred = parseInt(str[0], 10);
  //     const ten = parseInt(str[1], 10);
  //     const one = parseInt(str[2], 10);

  //     let words = [];

  //     if (hundred > 0) {
  //       words.push(ones[hundred] + ' Hundred');
  //     }

  //     if (ten === 1 && one > 0) {
  //       words.push(teens[one]);
  //     } else {
  //       if (ten > 0) {
  //         words.push(tens[ten]);
  //       }
  //       if (one > 0) {
  //         words.push(ones[one]);
  //       }
  //     }

  //     if (thousandIndex > 0 && words.length > 0) {
  //       words.push(thousands[thousandIndex]);
  //     }

  //     return words.join(' ');
  //   }

  //   // Handle the integer part of the amount
  //   function convertIntegerPart(integerPart) {
  //     const groups = [];
  //     let thousandIndex = 0;

  //     while (integerPart > 0) {
  //       groups.push(convertGroup(integerPart % 1000, thousandIndex));
  //       integerPart = Math.floor(integerPart / 1000);
  //       thousandIndex++;
  //     }

  //     // Insert newline character before the last three digits
  //     const resultGroups = groups.reverse().filter(group => group !== '');
  //     const lastGroupIndex = resultGroups.length - 1;
  //     const formattedGroups = resultGroups.map((group, index) =>
  //       index === lastGroupIndex ? `\n${group}` : group
  //     );

  //     return formattedGroups.join(' ');
  //   }

  //   // Handle the decimal part of the amount
  //   function convertDecimalPart(decimalPart) {
  //     if (decimalPart === 0) {
  //       return '';
  //     }

  //     let paise = Math.round(decimalPart * 100);
  //     return convertGroup(paise, 0) + ' Paise';
  //   }

  //   // Split the amount into integer and decimal parts
  //   const integerPart = Math.floor(amount);
  //   const decimalPart = amount - integerPart;

  //   // Convert both parts
  //   const integerWords = convertIntegerPart(integerPart);
  //   const decimalWords = convertDecimalPart(decimalPart);

  //   // Combine the results with line breaks
  //   const result = integerWords + ' Rupees' + (decimalWords ? '\nAnd ' + decimalWords : '');
  //   return result.trim();
  // }

  // Output: "Four Thousand Three Hundred Twenty Five Rupees and Sixteen Paise"


  const NETT_AMOUNT = parseFloat(data.sum_total) + (parseFloat(IGST) > 0 ? parseFloat(IGST) : (parseFloat(SGST) + parseFloat(CGST)));

  function convertRupeesToWordsWithBreaks(amount) {
    const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const thousands = ['', 'Thousand', 'Lakh', 'Crore']; // Adjusted for Indian system

    // Convert a three-digit group into words
    function convertGroup(number, thousandIndex) {
      if (number === 0) return '';

      let str = '000' + number;
      str = str.slice(-3);

      const hundred = parseInt(str[0], 10);
      const ten = parseInt(str[1], 10);
      const one = parseInt(str[2], 10);

      let words = [];

      if (hundred > 0) {
        words.push(ones[hundred] + ' Hundred');
      }

      if (ten === 1) {
        words.push(teens[one]);
      } else {
        if (ten > 0) {
          words.push(tens[ten]);
        }
        if (one > 0) {
          words.push(ones[one]);
        }
      }

      if (thousandIndex > 0 && words.length > 0) {
        words.push(thousands[thousandIndex]);
      }

      return words.join(' ');
    }

    // Handle the integer part of the amount
    function convertIntegerPart(integerPart) {
      const groups = [];
      let thousandIndex = 0;

      while (integerPart > 0) {
        groups.push(convertGroup(integerPart % (thousandIndex === 1 ? 100 : 1000), thousandIndex));
        integerPart = Math.floor(integerPart / (thousandIndex === 1 ? 100 : 1000));
        thousandIndex++;
      }

      // Insert newline character before the last three digits
      const resultGroups = groups.reverse().filter(group => group !== '');
      const lastGroupIndex = resultGroups.length - 1;
      const formattedGroups = resultGroups.map((group, index) =>
        index === lastGroupIndex ? `\n${group}` : group
      );

      return formattedGroups.join(' ');
    }

    // Handle the decimal part of the amount
    function convertDecimalPart(decimalPart) {
      if (decimalPart === 0) {
        return '';
      }

      let paise = Math.round(decimalPart * 100);
      return convertGroup(paise, 0) + ' Paise';
    }

    // Split the amount into integer and decimal parts
    const integerPart = Math.floor(amount);
    const decimalPart = amount - integerPart;

    // Convert both parts
    const integerWords = convertIntegerPart(integerPart);
    const decimalWords = convertDecimalPart(decimalPart);

    // Combine the results with line breaks
    const result = integerWords + ' Rupees' + (decimalWords ? '\nAnd ' + decimalWords : '');
    return result.trim();
  }
  const [userdata, setUserData] = useState([]);
  console.log('siva123', userdata);
  const filteredUserData = userdata.filter(item => item.company_id === data.party_details.company_id);
  console.log('Filtered userdata:', filteredUserData);
  const fetchData = async () => {
    try {
      const response = await fetch('https://billing.barbikan.in/company/list/joyg67j8t689t', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      //setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data);
        console.log(responseData)
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      //setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  useEffect(() => {

    fetchData();

  }, [])
  const words = convertRupeesToWordsWithBreaks(NETT_AMOUNT);
  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View>

        </View>
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={styles.headertext}>
              <Text style={styles.print}>[{type}] </Text>
              <View style={[styles.head]}>
                <Text style={styles.invoice}>Tax Invoice</Text>
              </View>
            </View>
          </View>
          {/* Table for address */}
          <View style={[styles.boxdetailss]}>
            <View style={[styles.box, styles.brdrights]}>
              <Text style={styles.billed}>Billed By :</Text>
              <Text style={styles.companyname}>{data.company_details.company_name}</Text>
              <Text >{data.company_details.address}</Text>
              <Text >{data.company_details.city}</Text>
              <Text >{data.company_details.state}</Text>
              <Text>GST :{data.company_details.gst_no}</Text>
              <Text>Ph :{data.company_details.mobile_number}</Text>
              <Text>FSSAI No : {data.company_details.fssai_code}</Text>
            </View>
            <View style={styles.box}>
              <Text style={styles.ewayno}>E-way Bill No: {data.eway_no}</Text>
              <Text style={styles.ewayno}>Vehicle No: {data.vechile_no}</Text>
              <Text style={styles.stateof}>State Of Supply: {data.state_of_supply}</Text>
            </View>
          </View>
          <View style={[styles.boxdetails]}>
            <View style={[styles.box, styles.brdright]}>
              <Text style={styles.billed}>Shipped To :</Text>
              <Text style={styles.companyname}>{data.party_details.company_name}</Text>
              <Text>GST :{data.party_details.gst_no}</Text>
              <Text>{data.party_details.billing_address}</Text>
              <Text>{data.party_details.city}</Text>
              <Text>{data.party_details.state}</Text>
              {/* <Text>{data.party_details.shipp_address}</Text> */}
              {/* <Text>Ph :{data.party_details.mobile_number}</Text> */}
            </View>
            <View style={styles.box}>
              <Text style={styles.ewayno}>Invoice No: {data.bill_no}</Text>
              <Text style={styles.ewayno}>Date: {moment(data.bill_date).format('DD-MM-YYYY')}</Text>
            </View>
          </View>
          <View style={styles.producthead}>
            <View style={styles.producttitle}>
              <Text style={styles.no}>S No</Text>
              <Text style={styles.particular}>Particular</Text>
              <Text style={styles.hsn}>HSN </Text>
              <Text style={styles.qty}>Qty & Unit</Text>
              <Text style={styles.rate}>Rate Per Unit</Text>
              <Text style={styles.gst}>TAX</Text>
              <Text style={styles.amount}>Amount</Text>
            </View>
            <View style={styles.bodydescription}>
              {data.product.map((product, index) => (
                <View style={styles.productbody} key={index}>
                  <Text style={styles.no}>{index + 1}</Text>
                  <View style={styles.particular}>
                    <Text>{product.product_name}</Text>
                    <Text style={styles.remarks}>{product.remarks}</Text>
                  </View>
                  <Text style={styles.qty}>{product.hsn_no}</Text>
                  <Text style={styles.qty}>{product.qty} {product.unit_name}</Text>
                  <Text style={styles.rate}>{product.price_unit}</Text>
                  <Text style={styles.gst}>{parseFloat(product.IGST) > 0 ? 'IGST@' + product.tax_percentage : 'GST@' + product.tax_percentage}%</Text>
                  <Text style={styles.amount}>{product.without_tax_amount}</Text>
                </View>
              ))};
            </View>
          </View>
          <View>

          </View>

          <View style={[styles.boxdetails]}>
            <View style={[styles.box, styles.brdright, styles.w70]}>
              <Text>Bank Name : {data.company_details.bank_name}</Text>
              <Text>Account No: {data.company_details.acc_no}</Text>
              <Text>IFSC  : {data.company_details.ifsc_code}</Text>
            </View>
            {parseFloat(IGST) > 0 ? (
              <View style={[styles.box, styles.w30]}>
                <Text> Total : {data.sum_total}</Text>
                <Text> IGST  :   {IGST.toFixed(2)}</Text>
              </View>
            ) : (
              <View style={[styles.box, styles.w30]}>
                <Text> Total     :  {data.sum_total}</Text>
                <Text> CGST    :  {CGST.toFixed(2)}</Text>
                <Text> SGST    :  {SGST.toFixed(2)}</Text>
              </View>)}
          </View>
          <View style={styles.footer}>
            <View style={styles.priceleft}>
              <View style={styles.priceRow}>
                <Text style={styles.priceCell}>Amount in words : {words}
                </Text>
                {parseFloat(IGST) > 0 ? (
                  <Text style={styles.overall}>NETT AMOUNT :  {(data.sum_total + parseFloat(IGST)).toFixed(2)} </Text>
                ) : (
                  <Text style={styles.overall}>NETT AMOUNT :{(data.sum_total + parseFloat(SGST) + parseFloat(CGST)).toFixed(2)} </Text>
                )}
              </View>
            </View>
          </View>
          <View style={styles.signbox}>
            <View style={styles.signdesk}>
              <View style={styles.signbill}>
                <View style={styles.customsign}>
                  <Text> Customer Sign</Text>
                </View>
                <Text style={styles.docs}>For {data.company_details.company_name} </Text>
              </View>
              <View>
                <Text style={styles.declration}> We Declare that this  invoice  shows the actual  price  of the goods described and that all particulars are  true and correct</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
export default Pdf;

